import React, { useEffect, useState } from 'react';
import {
    Box, Button, TextField, Typography, Card, CardContent, MenuItem, 
    Select, AppBar, Toolbar, Stack, DialogContent, IconButton, 
    DialogTitle, Dialog, Chip, Grid, CardMedia, InputLabel, 
    FormControl, Paper, Divider, DialogActions
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, fetchAllUsersData } from '../../redux/actions/usersActions';
import CloseIcon from '@mui/icons-material/Close';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PeopleIcon from '@mui/icons-material/People';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import TemplateIcon from '@mui/icons-material/Dashboard';
import TimelineIcon from '@mui/icons-material/Timeline';
import { addCommentData, assignedTasks, fetchAllTasksData, fetchTasksByStatus, imageUploadData, removeItem, updateList } from '../../redux/actions/tasksActions';
import axios from 'axios';
import { fetchAllCardsData } from '../../redux/actions/allCardsActions';
import { fetchAllTemplateData } from '../../redux/actions/templateActions';
import Loading from '../../components/Loading';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CommentIcon from '@mui/icons-material/Comment';
import FlagIcon from '@mui/icons-material/Flag';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { fetchAllProjectsData, createProjectData, deleteProjectData, updateProjectData } from '../../redux/actions/projectActions';
import FolderIcon from '@mui/icons-material/Folder';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { 
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot 
} from '@mui/lab';
import { toast } from 'react-hot-toast';

const employees = ['John Doe', 'Jane Smith', 'Emily Davis'];
const priorities = ['Low', 'Medium', 'High'];

function TaskView() {
    const [tasks, setTasks] = useState({
        todo: [],
        inProgress: [],
        complete: [],
    });

    const [newTask, setNewTask] = useState({
        title: '',
        description: '',
        assignedTo: '',
        priority: '',
        due_date: null,
        project_id: '',
    });

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const users = useSelector((state) => state?.users?.allUsersData?.data?.rows);
    const allTasks = useSelector((state) => state?.tasks?.allTasksData?.data?.rows);
    console.log("all tasks", allTasks)

    const [inProgressTask, setInProgressTask] = useState([]);
    const [completedTask, setCompletedTask] = useState([]);

    // Get data from Redux store with null checks
    const allCards = useSelector((state) => state?.allCards?.allCardsData?.data?.data?.cards || []);
    const allTemplate = useSelector((state) => state?.template?.templateData?.data?.data?.templates || []);
    const projects = useSelector((state) => state?.projects?.projectsData?.data?.data?.rows || []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await Promise.all([
                    dispatch(fetchAllCardsData()),
                    dispatch(fetchAllTemplateData()),
                    dispatch(fetchAllProjectsData())
                ]);
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [dispatch]);

    useEffect(() => {
        const status1 = 'In Progress'
        const status2 = 'Done'
        const getTaskManagerData = async () => {
            await dispatch(fetchAllUsersData());
            await dispatch(fetchAllTasksData());
            const response = await fetchTasksByStatus(status1);
            if (response) {
                setInProgressTask(response);
            }
            const response2 = await fetchTasksByStatus(status2);
            if (response2) {
                setCompletedTask(response2);
            }
        }
        getTaskManagerData();
    }, [dispatch]);

    // Add new task to To-Do list
    const addTask = async () => {
        const { title, description, assignedTo, priority, due_date, project_id } = newTask;
        if (title.trim() && assignedTo && priority && description && project_id) {
            try {
                const taskData = {
                    title,
                    description,
                    assigned_to: assignedTo,
                    priority,
                    due_date: due_date ? moment(due_date).format('YYYY-MM-DD') : null,
                    project_id,
                    status: 'To Do'
                };
                await assignedTasks(taskData);
                await dispatch(fetchAllTasksData());
                setNewTask({ title: '', description: '', assignedTo: '', priority: '', due_date: null, project_id: '' });
            } catch (error) {
                console.error('Error adding task:', error);
            }
        }
    };

    // Move task between lists
    const moveTask = async (id, status) => {
        const body = { task_id: id, status: status }
        await updateList(body);
        window.location.reload();
    };

    // Remove task from Complete list
    const removeTask = async (id) => {
        if (id) {
            await removeItem(id);
            window.location.reload();
        }
    };

    // Priority-based styles
    const getPriorityStyles = (priority) => {
        switch (priority) {
            case 'High':
                return { backgroundColor: '#ffcdd2', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)' };
            case 'Medium':
                return { backgroundColor: '#fff9c4', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)' };
            case 'Low':
                return { backgroundColor: '#c8e6c9', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)' };
            default:
                return {};
        }
    };

    const [openAddUsers, setOpenAddUsers] = useState(false);
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();

    const addUsers = () => {
        setOpenAddUsers(true);
    };
    const closeAddUsers = () => {
        setOpenAddUsers(false);
    };

    const handleAddUsers = async (e) => {
        e.preventDefault();
        const body = { first_name: firstName, last_name: lastName }
        console.log("body", body);
        const response = await createUser(body);
        setFirstName(null);
        setLastName(null);
        closeAddUsers();
    }

    const [openCommentsDialog, setOpenCommentsDialog] = useState(false);
    const [userId, setUserId] = useState();
    const [taskId, setTaskId] = useState();
    const [comment, setComment] = useState();

    const handleCommentDialog = (id) => {
        setTaskId(id);
        setOpenCommentsDialog(true);
    }

    const closeCommentDialog = () => {
        setOpenCommentsDialog(false);
    }

    const handleAddComments = async (e) => {
        e.preventDefault();
        const body = { task_id: taskId, user_id: userId, comment_text: comment }
        await addCommentData(body);
        setUserId(null);
        setTaskId(null);
        closeCommentDialog();
    }

    //show comments Dialog and attachments 
    const [openViewDialog, setOpenViewDialog] = useState(false);
    const [showDetails, setShowDetails] = useState();
    const handleViewDialog = (item) => {
        if (item) {
            setShowDetails(item);
            setOpenViewDialog(true);
        }
    }
    const closeViewDialog = () => {
        setOpenViewDialog(false);
    }

    // image upload 
    const [path, setpath] = useState();
    const [openImageUploadDialog, setImageUploadDialog] = useState(false);

    const handleFileUpload = async (event) => {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);

        try {
            const response = await axios.post('https://account.rajoffset.com/web/upload/v1/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setpath(response.data.data);
        } catch (error) {
        }
    };

    const handleImageUploadDialog = (id) => {
        setTaskId(id);
        setImageUploadDialog(true);
    }

    const closeImageUploadDialog = () => {
        setImageUploadDialog(false);
    }

    const handleImageUpload = async () => {
        if (path) {
            const body = { task_id: taskId, user_id: userId, file_path: path }
            await imageUploadData(body);
            setTaskId(null);
            setUserId(null);
            setpath(null);
            closeImageUploadDialog();
        }
    }

    // Task Item Component
    const TaskItem = ({ item, onClick }) => {
        const isOverdue = () => {
            if (item.status === 'Done') return false;
            if (!item.due_date) return false;
            return moment(item.due_date).isBefore(moment(), 'day');
        };

        const getPriorityColor = (priority) => {
            switch (priority) {
                case 'High': return '#ef5350';
                case 'Medium': return '#fb8c00';
                case 'Low': return '#66bb6a';
                default: return '#90a4ae';
            }
        };

        return (
            <Card 
                onClick={() => onClick(item)}
                elevation={2}
                sx={{
                    mb: 2,
                    borderRadius: 2,
                    transition: 'transform 0.2s',
                    bgcolor: isOverdue() ? '#ffebee' : 'white',
                    '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: 3,
                        cursor: 'pointer'
                    }
                }}
            >
                <CardContent sx={{ p: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Chip
                            label={`#${item.task_id}`}
                            size="small"
                            sx={{
                                bgcolor: 'primary.main',
                                color: 'white',
                                fontWeight: 500,
                                fontSize: '0.75rem',
                                height: '24px',
                                '& .MuiChip-label': {
                                    px: 1
                                }
                            }}
                        />
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <Chip
                                icon={<FolderIcon />}
                                label={item.project?.project_name}
                                size="small"
                                sx={{
                                    bgcolor: 'info.light',
                                    color: 'white',
                                    fontWeight: 500,
                                    '& .MuiSvgIcon-root': {
                                        color: 'white'
                                    }
                                }}
                            />
                            <Chip
                                icon={<FlagIcon />}
                                label={item.priority}
                                size="small"
                                sx={{
                                    bgcolor: `${getPriorityColor(item.priority)}15`,
                                    color: getPriorityColor(item.priority),
                                    fontWeight: 500
                                }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                            {item.title}
                        </Typography>
                    </Box>

                    {/* <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        {item.description}
                    </Typography> */}

                    <Stack spacing={1}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <PersonIcon sx={{ fontSize: 20, color: 'primary.main' }} />
                                <Typography variant="body2">
                                    {item?.user?.first_name} {item?.user?.last_name}
                                </Typography>
                            </Box>

                            {item.due_date && (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <CalendarTodayIcon sx={{ 
                                        fontSize: 20, 
                                        color: isOverdue() ? 'error.main' : 'warning.main' 
                                    }} />
                                    <Typography 
                                        variant="body2"
                                        sx={{ 
                                            color: isOverdue() ? 'error.main' : 'inherit',
                                            fontWeight: isOverdue() ? 500 : 400
                                        }}
                                    >
                                        Due: {moment(item.due_date).format('MMM DD, YYYY')}
                                    </Typography>
                                </Box>
                            )}
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            {item.comments?.length > 0 && (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                    <CommentIcon sx={{ fontSize: 18, color: 'text.secondary' }} />
                                    <Typography variant="body2" color="text.secondary">
                                        {item.comments.length}
                                    </Typography>
                                </Box>
                            )}
                            {item.attachments?.length > 0 && (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                    <AttachmentIcon sx={{ fontSize: 18, color: 'text.secondary' }} />
                                    <Typography variant="body2" color="text.secondary">
                                        {item.attachments.length}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
        );
    };

    const [openProjects, setOpenProjects] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [selectedProject, setSelectedProject] = useState(null);
    const [editingProject, setEditingProject] = useState(null);

    const handleAddProject = async (e) => {
        e.preventDefault();
        try {
            await createProjectData({
                project_name: projectName,
                project_description: projectDescription
            });
            await dispatch(fetchAllProjectsData());
            setProjectName('');
            setProjectDescription('');
            setOpenProjects(false);
        } catch (error) {
            console.error('Error creating project:', error);
        }
    };

    const handleEditProject = (project) => {
        setEditingProject(project);
        setProjectName(project.project_name);
        setProjectDescription(project.project_description);
    };

    const handleUpdateProject = async (e) => {
        e.preventDefault();
        try {
            await updateProjectData({
                project_id: editingProject.project_id,
                project_name: projectName,
                project_description: projectDescription
            });
            await dispatch(fetchAllProjectsData());
            setProjectName('');
            setProjectDescription('');
            setEditingProject(null);
        } catch (error) {
            console.error('Error updating project:', error);
        }
    };

    const handleDeleteProject = async (projectId) => {
        if (window.confirm('Are you sure you want to delete this project?')) {
            try {
                await deleteProjectData(projectId);
                await dispatch(fetchAllProjectsData());
            } catch (error) {
                console.error('Error deleting project:', error);
            }
        }
    };

    const [openNewTask, setOpenNewTask] = useState(false);

    const handleOpenNewTask = () => setOpenNewTask(true);
    const handleCloseNewTask = () => {
        setNewTask({ title: '', description: '', assignedTo: '', priority: '', due_date: null, project_id: '' });
        setOpenNewTask(false);
    };

    const handleDragEnd = async (result) => {
        console.log('Drag result:', result); // Debug full result object
        
        if (!result || !result.destination) {
            alert('No destination found:', result);
            return;
        }

        const { draggableId, source, destination } = result;
        
        console.log('Source:', source);
        console.log('Destination:', destination);
        console.log('DraggableId:', draggableId);

        // Don't do anything if dropped in same location
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            console.log('Dropped in same location');
            return;
        }

        // Extract the numeric task_id from the draggableId
        const taskId = draggableId.split('-')[1]; // Remove parseInt for now
        console.log('Moving task:', taskId, 'to status:', destination.droppableId);

        try {
            // Update task status in backend
            await updateList({
                task_id: taskId,
                status: destination.droppableId
            });

            // Refresh all lists immediately after update
            await Promise.all([
                dispatch(fetchAllTasksData()),
                fetchTasksByStatus('In Progress').then(response => {
                    if (response) setInProgressTask(response);
                }),
                fetchTasksByStatus('Done').then(response => {
                    if (response) setCompletedTask(response);
                })
            ]);

        } catch (error) {
            console.error('Error moving task:', { taskId, destination, error });
            toast.error('Failed to update task status');
            
            // Refresh all lists on error to ensure consistency
            await Promise.all([
                dispatch(fetchAllTasksData()),
                fetchTasksByStatus('In Progress').then(response => {
                    if (response) setInProgressTask(response);
                }),
                fetchTasksByStatus('Done').then(response => {
                    if (response) setCompletedTask(response);
                })
            ]);
        }
    };

    const [selectedTask, setSelectedTask] = useState(null);
    const [openTaskDetails, setOpenTaskDetails] = useState(false);
    const [newComment, setNewComment] = useState('');
    const [newAttachment, setNewAttachment] = useState(null);

    // Add state for edited values
    const [editedTitle, setEditedTitle] = useState('');
    const [editedDescription, setEditedDescription] = useState('');
    const [editedDueDate, setEditedDueDate] = useState(null);
    const [editedPriority, setEditedPriority] = useState('');

    const handleTaskClick = (task) => {
        setSelectedTask(task);
        setEditedTitle(task.title);
        setEditedDescription(task.description);
        setEditedDueDate(task.due_date ? moment(task.due_date) : null);
        setEditedPriority(task.priority);
        setOpenTaskDetails(true);
    };

    const handleCloseTaskDetails = () => {
        setSelectedTask(null);
        setOpenTaskDetails(false);
        setNewComment('');
        setNewAttachment(null);
    };

    const handleAddComment = async () => {
        if (newComment.trim()) {
            try {
                await addCommentData({
                    task_id: selectedTask.task_id,
                    comment_text: newComment,
                    user_id: selectedTask.user.user_id
                });
                await dispatch(fetchAllTasksData());
                setNewComment('');
            } catch (error) {
                console.error('Error adding comment:', error);
            }
        }
    };

    const handleAddAttachment = async () => {
        if (newAttachment) {
            try {
                await imageUploadData({
                    task_id: selectedTask.task_id,
                    user_id: selectedTask.user.user_id,
                    file_path: newAttachment
                });
                await dispatch(fetchAllTasksData());
                setNewAttachment(null);
            } catch (error) {
                console.error('Error adding attachment:', error);
            }
        }
    };

    const handleSaveChanges = async () => {
        try {
            await updateList({
                task_id: selectedTask.task_id,
                title: editedTitle,
                description: editedDescription,
                due_date: editedDueDate ? moment(editedDueDate).format('YYYY-MM-DD') : null,
                priority: editedPriority,
                status: selectedTask.status
            });
            await dispatch(fetchAllTasksData());
            toast.success('Task updated successfully');
        } catch (error) {
            console.error('Error updating task:', error);
            toast.error('Failed to update task');
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            {/* Enhanced Header */}
            <AppBar position="static" sx={{ 
                backgroundColor: 'white', 
                mb: 3,
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
            }}>
                <Toolbar sx={{ 
                    color: 'text.primary', 
                    justifyContent: 'space-between',
                    padding: '1rem'
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography variant="h5" sx={{ 
                            fontWeight: 600,
                            color: 'primary.main'
                        }}>
                            Task Manager Pro
                        </Typography>
                        <Button
                            variant="contained"
                            sx={{
                                textTransform: 'none',
                                borderRadius: 2,
                                boxShadow: 2
                            }}
                            onClick={addUsers}
                            startIcon={<PeopleIcon />}
                        >
                            Add User
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => setOpenProjects(true)}
                            startIcon={<FolderIcon />}
                        >
                            Projects
                        </Button>
                    </Box>
                    <Typography variant="subtitle1" sx={{ 
                        fontWeight: 500,
                        color: 'text.secondary'
                    }}>
                        Welcome, Admin!
                    </Typography>
                </Toolbar>
            </AppBar>

            {/* Statistics Cards */}
            <Box sx={{ mb: 4, px: 3 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper
                            elevation={0}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                bgcolor: 'primary.light',
                                color: 'white',
                                transition: 'transform 0.2s',
                                '&:hover': {
                                    transform: 'translateY(-5px)',
                                    boxShadow: 4
                                }
                            }}
                        >
                            <CreditCardIcon sx={{ fontSize: 40, mb: 1 }} />
                            <Typography variant="h6">Total Cards</Typography>
                            <Typography variant="h4" sx={{ mt: 1 }}>{allCards.length}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper
                            elevation={0}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                bgcolor: 'secondary.light',
                                color: 'white',
                                transition: 'transform 0.2s',
                                '&:hover': {
                                    transform: 'translateY(-5px)',
                                    boxShadow: 4
                                }
                            }}
                        >
                            <TemplateIcon sx={{ fontSize: 40, mb: 1 }} />
                            <Typography variant="h6">Total Templates</Typography>
                            <Typography variant="h4" sx={{ mt: 1 }}>{allTemplate.length}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper
                            elevation={0}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                bgcolor: '#4caf50',
                                color: 'white',
                                transition: 'transform 0.2s',
                                '&:hover': {
                                    transform: 'translateY(-5px)',
                                    boxShadow: 4
                                }
                            }}
                        >
                            <AssignmentIcon sx={{ fontSize: 40, mb: 1 }} />
                            <Typography variant="h6">Total Tasks</Typography>
                            <Typography variant="h4" sx={{ mt: 1 }}>{allTasks?.length || 0}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper
                            elevation={0}
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                borderRadius: 2,
                                bgcolor: '#ff9800',
                                color: 'white',
                                transition: 'transform 0.2s',
                                '&:hover': {
                                    transform: 'translateY(-5px)',
                                    boxShadow: 4
                                }
                            }}
                        >
                            <TimelineIcon sx={{ fontSize: 40, mb: 1 }} />
                            <Typography variant="h6">In Progress</Typography>
                            <Typography variant="h4" sx={{ mt: 1 }}>{inProgressTask?.data?.length || 0}</Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* Task Columns */}
            <DragDropContext onDragEnd={handleDragEnd}>
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: { xs: 'column', md: 'row' }, 
                    gap: 3, 
                    px: 3,
                    pb: 3
                }}>
                    {/* To-Do Column */}
                    <Card sx={{ 
                        flex: 1, 
                        bgcolor: '#ffffff', 
                        borderRadius: 2, 
                        boxShadow: '0 2px 10px rgba(0,0,0,0.08)',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100vh - 200px)' // Adjust this value based on your header height
                    }}>
                        <CardContent sx={{ 
                            flex: 1, 
                            display: 'flex', 
                            flexDirection: 'column',
                            overflow: 'hidden'
                        }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                                <Typography variant="h6" sx={{ color: 'primary.main', fontWeight: 600 }}>
                                    To-Do
                                </Typography>
                                <Button
                                    variant="contained"
                                    startIcon={<AddIcon />}
                                    onClick={handleOpenNewTask}
                                    sx={{ borderRadius: 2 }}
                                >
                                    New Task
                                </Button>
                            </Box>
                            <Divider sx={{ mb: 2 }} />
                            <Droppable droppableId="To Do" type="task">
                                {(provided) => (
                                    <Box
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        sx={{ 
                                            flex: 1,
                                            overflowY: 'auto',
                                            minHeight: '100px',
                                            '&::-webkit-scrollbar': {
                                                width: '8px',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: 'rgba(0,0,0,0.1)',
                                                borderRadius: '4px',
                                            }
                                        }}
                                    >
                                        {allTasks?.filter(item => item.status === 'To Do').map((item, index) => (
                                            <Draggable
                                                key={`task-${item.task_id}`}
                                                draggableId={`task-${item.task_id}`}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <Box
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <TaskItem
                                                            item={item}
                                                            onClick={handleTaskClick}
                                                        />
                                                    </Box>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </CardContent>
                    </Card>

                    {/* In Progress Column */}
                    <Card sx={{ 
                        flex: 1, 
                        bgcolor: '#ffffff', 
                        borderRadius: 2, 
                        boxShadow: '0 2px 10px rgba(0,0,0,0.08)',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100vh - 200px)' // Adjust this value based on your header height
                    }}>
                        <CardContent sx={{ 
                            flex: 1, 
                            display: 'flex', 
                            flexDirection: 'column',
                            overflow: 'hidden'
                        }}>
                            <Typography variant="h6" gutterBottom sx={{ color: 'warning.main', fontWeight: 600, mb: 3 }}>
                                In Progress
                            </Typography>
                            <Divider sx={{ mb: 2 }} />
                            <Droppable droppableId="In Progress" type="task">
                                {(provided) => (
                                    <Box
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        sx={{ 
                                            flex: 1,
                                            overflowY: 'auto',
                                            minHeight: '100px',
                                            '&::-webkit-scrollbar': {
                                                width: '8px',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: 'rgba(0,0,0,0.1)',
                                                borderRadius: '4px',
                                            }
                                        }}
                                    >
                                        {inProgressTask?.data?.map((item, index) => (
                                            <Draggable
                                                key={`task-${item.task_id}`}
                                                draggableId={`task-${item.task_id}`}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <Box
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <TaskItem
                                                            item={item}
                                                            onClick={handleTaskClick}
                                                        />
                                                    </Box>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </CardContent>
                    </Card>

                    {/* Complete Column */}
                    <Card sx={{ 
                        flex: 1, 
                        bgcolor: '#ffffff', 
                        borderRadius: 2, 
                        boxShadow: '0 2px 10px rgba(0,0,0,0.08)',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100vh - 200px)' // Adjust this value based on your header height
                    }}>
                        <CardContent sx={{ 
                            flex: 1, 
                            display: 'flex', 
                            flexDirection: 'column',
                            overflow: 'hidden'
                        }}>
                            <Typography variant="h6" gutterBottom sx={{ color: 'success.main', fontWeight: 600,  }}>
                                Complete
                            </Typography>
                            <Typography 
                                variant="body2" 
                                sx={{ 
                                    color: 'warning.dark',
                                    fontWeight: 500
                                }}
                            >
                                A task is considered as incomplete if codes are not pushed
                            </Typography>
                            <Divider sx={{ mb: 2 }} />
                            <Droppable droppableId="Done" type="task">
                                {(provided) => (
                                    <Box
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        sx={{ 
                                            flex: 1,
                                            overflowY: 'auto',
                                            minHeight: '100px',
                                            '&::-webkit-scrollbar': {
                                                width: '8px',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: 'rgba(0,0,0,0.1)',
                                                borderRadius: '4px',
                                            }
                                        }}
                                    >
                                        {completedTask?.data?.map((item, index) => (
                                            <Draggable
                                                key={`task-${item.task_id}`}
                                                draggableId={`task-${item.task_id}`}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <Box
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <TaskItem
                                                            item={item}
                                                            onClick={handleTaskClick}
                                                        />
                                                    </Box>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </CardContent>
                    </Card>
                </Box>
            </DragDropContext>

            <Dialog open={openAddUsers} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Create User</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeAddUsers}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleAddUsers}>
                        <Stack spacing={2} margin={2}>
                            <TextField variant='outlined' value={firstName} onChange={e => setFirstName(e.target.value)} label="First Name" />
                            <TextField variant='outlined' value={lastName} onChange={e => setLastName(e.target.value)} label="Last Name" />
                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeAddUsers}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Submit</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            <Dialog open={openCommentsDialog} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Add Comments</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeCommentDialog}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleAddComments}>
                        <Stack spacing={2} margin={2}>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">User Id</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="User Id"
                                    onChange={e => setUserId(e.target.value)}
                                >
                                    {users.length > 0 ? (
                                        users?.map((card) => (
                                            <MenuItem key={card.user_id} value={card.user_id}>{card.first_name} {card.last_name}</MenuItem>
                                        ))
                                    ) : (
                                        <Typography variant="body1" color="initial"></Typography>
                                    )}
                                </Select>
                            </FormControl>
                            <TextField variant='outlined' value={comment} onChange={e => setComment(e.target.value)} label="Comment" />

                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeCommentDialog}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Submit</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            <Dialog open={openViewDialog} onClose={closeViewDialog} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Show Comments and Images</Typography>
                    </DialogTitle>
                </Box>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {showDetails?.comments?.map((cmt, i) => (
                            <Typography key={i} textAlign="center" variant="h6" color="textSecondary">
                                Comment: {cmt?.comment_text} by {cmt?.user?.first_name} {cmt?.user?.last_name}
                            </Typography>
                        ))}

                        <Grid
                            container
                            spacing={2}
                            sx={{ marginTop: 2, justifyContent: 'center' }}
                        >
                            {showDetails?.attachments?.slice(0, 4).map((img, index) => (
                                <Grid item xs={6} sm={3} key={index}>
                                    <Box
                                        component="img"
                                        src={img?.file_path}
                                        alt={`Image ${index + 1}`}
                                        sx={{
                                            width: '100%',
                                            height: 'auto',
                                            borderRadius: 2,
                                            boxShadow: 1,
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={openImageUploadDialog} onClose={closeImageUploadDialog} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Add Images</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeImageUploadDialog}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleImageUpload}>
                        <Stack spacing={2} margin={2}>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">User Id</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="User Id"
                                    onChange={e => setUserId(e.target.value)}
                                >
                                    {users.length > 0 ? (
                                        users?.map((card) => (
                                            <MenuItem key={card.user_id} value={card.user_id}>{card.first_name} {card.last_name}</MenuItem>
                                        ))
                                    ) : (
                                        <Typography variant="body1" color="initial"></Typography>
                                    )}
                                </Select>
                            </FormControl>

                            <TextField
                                fullWidth
                                label="images"
                                type="file"
                                onChange={event => handleFileUpload(event)}
                                InputLabelProps={{ shrink: true }}
                            ></TextField>

                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeImageUploadDialog}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Submit</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            <Dialog open={openProjects} onClose={() => setOpenProjects(false)} maxWidth="md" fullWidth>
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">Projects</Typography>
                        <IconButton onClick={() => setOpenProjects(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>Add New Project</Typography>
                        <form onSubmit={editingProject ? handleUpdateProject : handleAddProject}>
                            <Stack spacing={2}>
                                <TextField
                                    label="Project Name"
                                    value={projectName}
                                    onChange={(e) => setProjectName(e.target.value)}
                                    fullWidth
                                    required
                                />
                                <TextField
                                    label="Project Description"
                                    value={projectDescription}
                                    onChange={(e) => setProjectDescription(e.target.value)}
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                                <Button type="submit" variant="contained">
                                    {editingProject ? 'Update Project' : 'Add Project'}
                                </Button>
                                {editingProject && (
                                    <Button 
                                        variant="outlined" 
                                        onClick={() => {
                                            setEditingProject(null);
                                            setProjectName('');
                                            setProjectDescription('');
                                        }}
                                    >
                                        Cancel Edit
                                    </Button>
                                )}
                            </Stack>
                        </form>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="h6" sx={{ mb: 2 }}>Existing Projects</Typography>
                    <Grid container spacing={2}>
                        {projects?.map((project) => (
                            <Grid item xs={12} md={6} key={project.project_id}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h6">{project.project_name}</Typography>
                                        <Typography color="textSecondary">
                                            {project.project_description}
                                        </Typography>
                                        <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                onClick={() => handleEditProject(project)}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                color="error"
                                                onClick={() => handleDeleteProject(project.project_id)}
                                            >
                                                Delete
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog 
                open={openNewTask} 
                onClose={handleCloseNewTask}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">Create New Task</Typography>
                        <IconButton onClick={handleCloseNewTask}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <TextField
                                label="Task Title"
                                value={newTask.title}
                                onChange={(e) => setNewTask((prev) => ({ ...prev, title: e.target.value }))}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Task Description"
                                value={newTask.description}
                                onChange={(e) => setNewTask((prev) => ({ ...prev, description: e.target.value }))}
                                fullWidth
                                multiline
                                rows={4}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required>
                                <InputLabel>Assign To</InputLabel>
                                <Select
                                    value={newTask.assignedTo}
                                    onChange={(e) => setNewTask((prev) => ({ ...prev, assignedTo: e.target.value }))}
                                    label="Assign To"
                                >
                                    {users?.map((employee) => (
                                        <MenuItem key={employee.user_id} value={employee.user_id}>
                                            {employee.first_name} {employee.last_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required>
                                <InputLabel>Priority</InputLabel>
                                <Select
                                    value={newTask.priority}
                                    onChange={(e) => setNewTask((prev) => ({ ...prev, priority: e.target.value }))}
                                    label="Priority"
                                >
                                    {priorities.map((priority) => (
                                        <MenuItem key={priority} value={priority}>
                                            {priority}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required>
                                <InputLabel>Project</InputLabel>
                                <Select
                                    value={newTask.project_id}
                                    onChange={(e) => setNewTask((prev) => ({ ...prev, project_id: e.target.value }))}
                                    label="Project"
                                >
                                    {projects?.map((project) => (
                                        <MenuItem key={project.project_id} value={project.project_id}>
                                            {project.project_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                label="Due Date"
                                value={newTask.due_date}
                                onChange={(newValue) => setNewTask((prev) => ({ ...prev, due_date: newValue }))}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        fullWidth
                                        required
                                    />
                                )}
                                disablePast
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                    <Button 
                        variant="outlined" 
                        onClick={handleCloseNewTask}
                        startIcon={<CloseIcon />}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            addTask();
                            handleCloseNewTask();
                        }}
                        startIcon={<SaveIcon />}
                        disabled={!newTask.title || !newTask.description || !newTask.assignedTo || !newTask.priority || !newTask.project_id}
                    >
                        Create Task
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog 
                open={openTaskDetails} 
                onClose={handleCloseTaskDetails}
                maxWidth="md"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: 2,
                        bgcolor: '#f8f9fa'
                    }
                }}
            >
                {selectedTask && (
                    <>
                        <DialogTitle>
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'space-between', 
                                alignItems: 'center',
                                bgcolor: 'primary.main',
                                mx: -3,
                                mt: -3,
                                px: 3,
                                py: 2,
                                color: 'white',
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8
                            }}>
                                <Box>
                                    <Typography variant="h5" sx={{ fontWeight: 600 }}>
                                        Task Details
                                    </Typography>
                                    <Typography 
                                        variant="subtitle2" 
                                        sx={{ 
                                            opacity: 0.9,
                                            mt: 0.5,
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1
                                        }}
                                    >
                                        <span>Task ID:</span>
                                        <Chip
                                            label={`#${selectedTask.task_id}`}
                                            size="small"
                                            sx={{
                                                bgcolor: 'rgba(255, 255, 255, 0.2)',
                                                color: 'white',
                                                fontWeight: 500,
                                                fontSize: '0.75rem',
                                                height: '24px',
                                                '& .MuiChip-label': {
                                                    px: 1
                                                }
                                            }}
                                        />
                                    </Typography>
                                </Box>
                                <IconButton onClick={handleCloseTaskDetails}>
                                    <CloseIcon sx={{ color: 'white' }} />
                                </IconButton>
                            </Box>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Card sx={{ p: 2, bgcolor: 'background.paper' }}>
                                        <TextField
                                            fullWidth
                                            label="Title"
                                            value={editedTitle}
                                            onChange={(e) => setEditedTitle(e.target.value)}
                                            sx={{ mb: 2 }}
                                        />

                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={4}
                                            label="Description"
                                            value={editedDescription}
                                            onChange={(e) => setEditedDescription(e.target.value)}
                                            sx={{ mb: 2 }}
                                        />

                                        <FormControl fullWidth sx={{ mb: 2 }}>
                                            <InputLabel>Priority</InputLabel>
                                            <Select
                                                value={editedPriority}
                                                onChange={(e) => setEditedPriority(e.target.value)}
                                                label="Priority"
                                            >
                                                <MenuItem value="Low">Low</MenuItem>
                                                <MenuItem value="Medium">Medium</MenuItem>
                                                <MenuItem value="High">High</MenuItem>
                                            </Select>
                                        </FormControl>

                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                label="Due Date"
                                                value={editedDueDate}
                                                onChange={(newValue) => setEditedDueDate(newValue)}
                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                            />
                                        </LocalizationProvider>
                                    </Card>
                                </Grid>

                                {/* Comments Section */}
                                <Grid item xs={12}>
                                    <Typography 
                                        variant="h6" 
                                        sx={{ 
                                            mb: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            color: 'primary.main',
                                            fontWeight: 600
                                        }}
                                    >
                                        <CommentIcon /> Comments
                                    </Typography>
                                    <Box sx={{ mb: 2 }}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={2}
                                            placeholder="Add a comment..."
                                            value={newComment}
                                            onChange={(e) => setNewComment(e.target.value)}
                                            sx={{ 
                                                mb: 1,
                                                '& .MuiOutlinedInput-root': {
                                                    bgcolor: 'white',
                                                    borderRadius: 2
                                                }
                                            }}
                                        />
                                        <Button
                                            variant="contained"
                                            onClick={handleAddComment}
                                            disabled={!newComment.trim()}
                                            sx={{ borderRadius: 2 }}
                                        >
                                            Add Comment
                                        </Button>
                                    </Box>
                                    <Timeline>
                                        {selectedTask.comments?.map((comment) => (
                                            <TimelineItem key={comment.comment_id}>
                                                <TimelineSeparator>
                                                    <TimelineDot color="primary" />
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <Card 
                                                        sx={{ 
                                                            bgcolor: 'white',
                                                            borderRadius: 2,
                                                            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                                                            ml: 2,
                                                            mb: 2
                                                        }}
                                                    >
                                                        <CardContent>
                                                            <Typography 
                                                                variant="subtitle2"
                                                                sx={{ 
                                                                    fontWeight: 600,
                                                                    color: 'primary.main'
                                                                }}
                                                            >
                                                                {comment.user.first_name} {comment.user.last_name}
                                                            </Typography>
                                                            <Typography color="textSecondary" variant="caption" display="block">
                                                                {moment(comment.created_at).format('MMM DD, YYYY HH:mm')}
                                                            </Typography>
                                                            <Typography sx={{ mt: 1 }}>{comment.comment_text}</Typography>
                                                        </CardContent>
                                                    </Card>
                                                </TimelineContent>
                                            </TimelineItem>
                                        ))}
                                    </Timeline>
                                </Grid>

                                {/* Attachments Section */}
                                <Grid item xs={12}>
                                    <Typography variant="h6" sx={{ mb: 2 }}>Attachments</Typography>
                                    <Box sx={{ mb: 2 }}>
                                        <input
                                            type="file"
                                            onChange={(e) => setNewAttachment(e.target.files[0])}
                                            style={{ display: 'none' }}
                                            id="attachment-input"
                                        />
                                        <label htmlFor="attachment-input">
                                            <Button
                                                variant="outlined"
                                                component="span"
                                                startIcon={<AttachmentIcon />}
                                            >
                                                Add Attachment
                                            </Button>
                                        </label>
                                        {newAttachment && (
                                            <Button
                                                variant="contained"
                                                onClick={handleAddAttachment}
                                                sx={{ ml: 1 }}
                                            >
                                                Upload
                                            </Button>
                                        )}
                                    </Box>
                                    <Grid container spacing={2}>
                                        {selectedTask.attachments?.map((attachment) => (
                                            <Grid item xs={12} sm={6} md={4} key={attachment.attachment_id}>
                                                <Card variant="outlined">
                                                    <CardContent>
                                                        <Typography variant="subtitle2" noWrap>
                                                            {attachment.file_path.split('/').pop()}
                                                        </Typography>
                                                        <Typography color="textSecondary" variant="caption" display="block">
                                                            By: {attachment.user?.first_name} {attachment.user?.last_name}
                                                        </Typography>
                                                        <Button
                                                            size="small"
                                                            startIcon={<DownloadIcon />}
                                                            href={attachment.file_path}
                                                            target="_blank"
                                                        >
                                                            Download
                                                        </Button>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{ p: 3, bgcolor: 'white' }}>
                            {selectedTask.status === 'Done' && (
                                <Button
                                    color="error"
                                    variant="contained"
                                    startIcon={<DeleteIcon />}
                                    sx={{ borderRadius: 2 }}
                                    onClick={() => {
                                        removeTask(selectedTask.task_id);
                                        handleCloseTaskDetails();
                                    }}
                                >
                                    Delete the Task
                                </Button>
                            )}
                            <Button 
                                onClick={handleCloseTaskDetails}
                                variant="outlined"
                                sx={{ borderRadius: 2 }}
                            >
                                Close
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSaveChanges}
                                startIcon={<SaveIcon />}
                                sx={{ borderRadius: 2 }}
                            >
                                Save Changes
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </LocalizationProvider>
    );
}

export default TaskView;
