import * as actionTypes from '../actionTypes.js';


const initialState = {
    customGroupData: null,
    loading: false,
    error: null,
};

const customGroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_CUSTOM_GROUP_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.GET_ALL_CUSTOM_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                customGroupData: action.payload,
            };
        case actionTypes.GET_ALL_CUSTOM_GROUP_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
export default customGroupReducer;