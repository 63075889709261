import * as actionTypes from "../actionTypes.js";

const initialState = {
  user: null,
  isAuthenticated: false,
  showOtpInput: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_OTP_INPUT:
      return {
        ...state,
        showOtpInput: action.payload.showOtpInput,
        isAuthenticated: false,
        user: null,
      };
    case actionTypes.LOG_IN:
      
      console.log(state);
      localStorage.setItem("user", JSON.stringify(action.payload.user_info));
      localStorage.setItem("access_token", JSON.stringify(action.payload.access_token.token));
      localStorage.setItem("refresh_token", JSON.stringify(action.payload.refresh_token));
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload
      };


    case actionTypes.LOG_OUT:
      localStorage.clear();
      return initialState;

    default:
      return state;
  }
};

export default authReducer;