import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const getFrameEntryRequest = () => ({
    type: actionTypes.GET_ALL_FRAME_ENTRY_REQUEST,
});

export const getFrameEntrySuccess = (data) => ({
    type: actionTypes.GET_ALL_FRAME_ENTRY_SUCCESS,
    payload: data,
});

export const getFrameEntryFailure = (error) => ({
    type: actionTypes.GET_ALL_FRAME_ENTRY_FAILURE,
    payload: error,
});

export const getFrameEntryData = (page, limit, search) => {
    return async (dispatch) => {
        dispatch(getFrameEntryRequest());
        try{
            const data = await api.getFrameEntry(page, limit, search);
            dispatch(getFrameEntrySuccess(data));
            return data;

        }catch(error){
            dispatch(getFrameEntryFailure(error));
        }
    }
};

export const createFrameEntryData = async (formData) => {
    const data = await api.createFrameEntry(formData);
    return data;
};
export const editFrameEntryData = async (formData) => {
    const data = await api.editFrameEntry(formData.id, formData);
    return data;
};
export const deleteFrameEntryData = async (id) => {
    const data = await api.deleteFrameEntry(id);
    return data;
};

export const regenerateFrameEntryData = async (formData) => {
    const data = await api.regenerateFrameEntry(formData.id, formData);
    return data;
};
export const paymentResyncFrameEntryData = async (formData) => {
    const data = await api.paymentResyncFrameEntry(formData.id, formData);
    return data;
};
export const downloadFrameEntryData = async (formData) => {
    const data = await api.downloadFrameEntry(formData.id, formData);
    return data;
};
export const suspendFrameEntryData = async (formData) => {
    const data = await api.suspendFrameEntry(formData.id, formData);
    return data;
};

