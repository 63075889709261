import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const fetchAllTasksRequest = () => ({
    type: actionTypes.GET_ALL_TASKS_REQUEST,
});
export const fetchAllTasksSuccess = (data) => ({
    type: actionTypes.GET_ALL_TASKS_SUCCESS,
    payload: data,
});
export const fetchAllTasksFailure = (error) => ({
    type: actionTypes.GET_ALL_TASKS_FAILURE,
    payload: error,
});

export const fetchAllTasksData = () => {

    return async (dispatch) => {
        dispatch(fetchAllTasksRequest());
        try {
            const {data} = await api.allTasks();
            console.log("Tasks data", data);
            dispatch(fetchAllTasksSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchAllTasksFailure(error));
        }
    };
};

export const assignedTasks = async (formData) => {
    console.log("formData in Action",formData);
    const data = await api.addTasks(formData.assigned_to, formData);
    return data;
}

export const fetchTasksByStatus = async (status) => {
    const {data} = await api.allTasksByStatus(status);
    return data;
}

export const updateList = async (formData) => {
    const data = await api.updateStatus(formData?.task_id, formData)
    return data;
}

export const removeItem = async (id) => {
    const data = await api.deleteTasks(id);
    return data;
};

export const addCommentData = async (formData) => {
    const data = await api.addComments(formData?.task_id, formData);
    return data;
}

export const imageUploadData = async (formData) => {
    const data = await api.imageUpload(formData?.task_id, formData);
    return data;
}