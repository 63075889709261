import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const fetchAllBusinessRequest = () => ({
    type: actionTypes.GET_ALL_BUSINESSES_REQUEST,
});
export const fetchAllBusinessSuccess = (data) => ({
    type: actionTypes.GET_ALL_BUSINESSES_SUCCESS,
    payload: data,
});
export const fetchAllBusinessFailure = (error) => ({
    type: actionTypes.GET_ALL_BUSINESSES_FAILURE,
    payload: error,
});

export const fetchAllBusinessData = (page, limit,search= '') => {

    return async (dispatch) => {
        dispatch(fetchAllBusinessRequest());
        try {
            const {data} = await api.allBusinesses(page, limit,search);
            console.log("data in Business action", data);
            dispatch(fetchAllBusinessSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchAllBusinessFailure(error));
        }
    };
};

// export const createPartnerCardEntryData = async (newCardData) => {
//     const {data} = await api.createPartnerCardEntry(newCardData);
//     return data;
// };

// export const updatePartnerCardEntryData = async (updatedData) => {
//     const response = await api.updatePartnerCardEntry(updatedData.id, updatedData);
//     return response?.data;
// };

// export const deletePartnerCardEntryData = async (id) => {
//     const data = await api.deletePartnerCardEntry(id);
//     return data;
// }