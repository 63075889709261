import * as actionTypes from '../actionTypes.js';


const initialState = {
    frameEntryValueData: null,
    loading: false,
    error: null,
};

const frameEntryValueReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_FRAME_ENTRY_VALUE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.GET_FRAME_ENTRY_VALUE_SUCCESS:
            return {
                ...state,
                loading: false,
                frameEntryValueData: action.payload,
            };
        case actionTypes.GET_FRAME_ENTRY_VALUE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
export default frameEntryValueReducer;