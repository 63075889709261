import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const getAllCustomTemplateRequest = () => ({
    type: actionTypes.GET_ALL_CUSTOM_TEMPLATES_REQUEST,
});

export const getALLCustomTemplateSuccess = (data) => ({
    type: actionTypes.GET_ALL_CUSTOM_TEMPLATES_SUCCESS,
    payload: data,
});

export const getAllCustomTemplateFailure = (error) => ({
    type: actionTypes.GET_ALL_CUSTOM_TEMPLATES_FAILURE,
    payload: error,
});

export const getALLCustomTemplateData = () => {
    return async (dispatch) => {
        dispatch(getAllCustomTemplateRequest());
        try{
            const data = await api.getCustomTemplate();
            dispatch(getALLCustomTemplateSuccess(data));
            return data;
        }catch(error) {
            dispatch(getAllCustomTemplateFailure(error));
        }
    }
};

export const createCustomTemplateData = async (formData) => {
    const data = await api.createCustomTemplate(formData);
    return data;
}