const initialState = {
    projectsData: {
        loading: false,
        data: null,
        error: null
    }
};

const projectsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_PROJECTS_REQUEST':
            return {
                ...state,
                projectsData: {
                    ...state.projectsData,
                    loading: true
                }
            };
        case 'GET_ALL_PROJECTS_SUCCESS':
            return {
                ...state,
                projectsData: {
                    loading: false,
                    data: action.payload,
                    error: null
                }
            };
        case 'GET_ALL_PROJECTS_FAILURE':
            return {
                ...state,
                projectsData: {
                    loading: false,
                    data: null,
                    error: action.payload
                }
            };
        default:
            return state;
    }
};

export default projectsReducer; 