import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel,
    Menu, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography,
    FormControlLabel, Switch, Divider, Grid, Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SaveIcon from "@mui/icons-material/Save";
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { createTemplateFieldData, fetchTemplateFieldData, updateTemplateFieldData, deleteTemplateFieldData } from '../../redux/actions/templateFieldActions';
import { fetchAllTemplateData } from '../../redux/actions/templateActions';
import { isAllowedToAccess } from '../../components/PermissionManager';
import { useNavigate, useParams } from 'react-router-dom';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CategoryIcon from '@mui/icons-material/Category';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import LabelIcon from '@mui/icons-material/Label';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import RuleIcon from '@mui/icons-material/Rule';
import CodeIcon from '@mui/icons-material/Code';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function FrameTemplateField() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const templateField = useSelector((state) => state?.templateField?.templateFieldData?.data?.data);
    const [loading, setLoading] = useState(true);
    const [editedRows, setEditedRows] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenuClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setEditFieldId(id);
        setDeleteFieldId(id);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setEditFieldId(null);
        setDeleteFieldId(null);
    };

    // pagination 

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState('');
    const limit = 50;

    useEffect(() => {
        fetchTemplateFieldData(page, limit);
    }, [page]);

    const handlePageChange = async (event, value) => {
        console.log("value", value);
        await setPage(value);
        await dispatch(fetchTemplateFieldData(value, limit));
    };

    const handleSearch = async () => {
        setPage(1);
        setLoading(true);
        await dispatch(fetchTemplateFieldData(1, limit, search));
        setLoading(false);
    };

    useEffect(() => {
        const fetchTemplateField = async () => {
            setLoading(true);
            await dispatch(fetchAllTemplateData());
            let rsp = await dispatch(fetchTemplateFieldData(params.frame_template_id));
            console.log("rsp in frame template field", rsp);
            let total = rsp?.data?.data?.pagination?.total;
            let pages = Math.ceil(total / Number(limit));
            setTotalPages(pages);
            setLoading(false);
        };

        fetchTemplateField();
    }, [dispatch, params.frame_template_id]);

    const columns = [
        { id: 'fieldId', name: 'Id' },
        { id: 'fieldName', name: 'Field Name' },


        { id: 'fieldLabel', name: 'Field Label' },
        { id: 'fieldValue', name: 'Field Value' },
        { id: 'fieldPlaceholder', name: 'Field Placeholder' },
        { id: 'fieldType', name: 'Field Type' },
        { id: 'fieldValidation', name: 'Field Validation' },
        { id: 'fieldOrder', name: 'Field Order' },
        { id: 'fieldRequired', name: 'Checks' },
        { id: 'actions', name: 'Actions' }
    ];

    // Add functionality
    const [open, setOpen] = useState(false);
    const [fieldName, setFieldName] = useState('');
    const [fieldType, setFieldType] = useState('string');
    const [fieldOrder, setFieldOrder] = useState(50);
    const [fieldValue, setFieldValue] = useState('');
    const [fieldRequired, setFieldRequired] = useState(true);
    const [fieldPlaceHolder, setFieldPlaceHolder] = useState('');
    const [fieldLabel, setFieldLabel] = useState('');
    const [fieldValidation, setFieldValidation] = useState('');
    const [fieldGroupCode, setFieldGroupCode] = useState('');

    const functionAdd = () => {
        openPopup();
    };

    const closePopup = () => {
        setOpen(false);
    };

    const openPopup = () => {
        setOpen(true);
    };

    const handleTemplateFieldSubmit = async (e) => {
        e.preventDefault();
        const newField = {
            frame_template_id: params.frame_template_id,
            field_name: fieldName,
            field_placeholder: fieldPlaceHolder,
            field_type: fieldType,
            field_order: fieldOrder,
            field_label: fieldLabel,
            field_value: fieldValue,
            field_required: fieldRequired,
            field_validation: fieldValidation,
            field_group_code: fieldGroupCode
        };
        await dispatch(createTemplateFieldData(newField));
        await dispatch(fetchTemplateFieldData(params.frame_template_id));
        setFieldName('');
        setFieldOrder('');
        setFieldType('');
        setFieldLabel('');
        setFieldValue('');
        setFieldRequired(false);
        setFieldPlaceHolder('');
        setFieldValidation('');
        setFieldGroupCode('');
        closePopup();
    };

    // Edit functionality
    const [editOpen, setEditOpen] = useState(false);
    const [editFieldId, setEditFieldId] = useState('');
    const [editFieldName, setEditFieldName] = useState('');
    const [editFieldType, setEditFieldType] = useState('');
    const [editFieldOrder, setEditFieldOrder] = useState('');
    const [editFieldPlaceholder, setEditFieldPlaceholder] = useState('');
    const [editFieldLabel, setEditFieldLabel] = useState('');
    const [editFieldValue, setEditFieldValue] = useState('');
    const [editFieldValidation, setEditFieldValidation] = useState('');
    const [editFieldRequired, setEditFieldRequired] = useState(false);
    const [editFieldGroupCode, setEditFieldGroupCode] = useState('');
    const [editIsHidden, setEditIsHidden] = useState(false);
    const [editIsLocked, setEditIsLocked] = useState(false);

    const functionFieldEdit = useCallback((field) => {
        setEditFieldId(field.id);
        setEditFieldName(field.field_name);
        setEditFieldType(field.field_type);
        setEditFieldOrder(field.field_order);
        setEditFieldPlaceholder(field.field_placeholder);
        setEditFieldLabel(field.field_label);
        setEditFieldValue(field.field_value);
        setEditFieldValidation(field.field_validation);
        setEditFieldRequired(field.field_required);
        setEditFieldGroupCode(field.field_group_code);
        setEditIsHidden(field.is_hidden);
        setEditIsLocked(field.is_locked);
        setEditOpen(true);
    }, []);

    const closeEditPopup = () => {
        setEditOpen(false);
    };

    const handleTemplateFieldEditSubmit = async (e) => {
        e.preventDefault();
        const updatedField = {
            id: editFieldId,
            frame_template_id: params.frame_template_id,
            field_name: editFieldName,
            field_type: editFieldType,
            field_order: editFieldOrder,
            field_placeholder: editFieldPlaceholder,
            field_label: editFieldLabel,
            field_value: editFieldValue,
            field_validation: editFieldValidation,
            field_required: editFieldRequired,
            field_group_code: editFieldGroupCode,
            is_hidden: editIsHidden,
            is_locked: editIsLocked,
        };
        try {
            const response = await dispatch(updateTemplateFieldData(updatedField));
            await dispatch(fetchTemplateFieldData(params.frame_template_id));
            toast.success(response.message || 'Field updated successfully');
            closeEditPopup();
        } catch (error) {
            toast.error(error?.message || 'Failed to update field');
            console.error('Update error:', error);
        }
    };

    // Delete functionality
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteFieldId, setDeleteFieldId] = useState('');

    const functionDelete = useCallback((id) => {
        setDeleteFieldId(id);
        setDeleteOpen(true);
    }, []);

    const closeDeletePopup = () => {
        setDeleteOpen(false);
    };

    const handleFieldDelete = async () => {
        if (deleteFieldId) {
            await dispatch(deleteTemplateFieldData(deleteFieldId));
            await dispatch(fetchTemplateFieldData(params.frame_template_id));
            closeDeletePopup();
        }
    };

    const accessRole = useSelector((state) => state?.auth?.user?.user_info?.meta_data);

    return (
        <>
            {isAllowedToAccess(accessRole, 'netaji_role', 'designer') ? (
                <>
                    <Box position="static" sx={{ borderRadius: 3, color: 'black' }}>
                        <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: { xs: 'flex-start', md: 'space-between' },
                                width: '100%'
                            }}>
                                <Typography sx={{
                                    fontSize: { xs: '20px', sm: '24px', md: '28px' },
                                    mb: { xs: 2, md: 0 },
                                    textAlign: {}
                                }}>
                                    Frame Template Fields (also known as Profile Fields)
                                </Typography>

                                {isAllowedToAccess(accessRole, 'netaji_role', 'manager') &&
                                    <Box sx={{
                                        display: 'flex',
                                        gap: { xs: 1, md: 0.5 },
                                        flexDirection: { xs: 'column', md: 'row' },
                                        justifyContent: { xs: 'center', md: 'flex-end' },
                                        width: { xs: '100%', md: 'auto' },
                                        alignItems: 'center'
                                    }}>
                                        <Button variant='contained' sx={{
                                            backgroundColor: 'midnightblue',
                                            textTransform: 'none',
                                            fontSize: { xs: '12px', sm: '14px', md: '16px' },
                                            width: { xs: '100%', sm: 'auto' }
                                        }} onClick={functionAdd}>Add Field </Button>
                                    </Box>
                                }
                            </Box>
                        </Toolbar>
                    </Box>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell key={column.id}>{column.name}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            <Loading />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    templateField && templateField.length === 0 ? (
                                        <Typography>No Field Added Till Now</Typography>
                                    ) : (
                                        templateField && templateField.map((field) => (
                                            <TableRow key={field.id}>
                                                <TableCell>{field.id}</TableCell>
                                                <TableCell>{field.field_name}</TableCell>


                                                <TableCell>{field.field_label}</TableCell>
                                                <TableCell>
                                                    {field.field_value && (() => {
                                                        const url = field.field_value;
                                                        const extension = url.split('.').pop().toLowerCase();

                                                        if (['mp4', 'webm'].includes(extension)) {
                                                            return (
                                                                <video width="100" height="auto" controls>
                                                                    <source src={url} type={`video/${extension}`} />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            );
                                                        } else if (['jpg', 'jpeg', 'png', 'webp'].includes(extension)) {
                                                            return (
                                                                <img
                                                                    src={url}
                                                                    alt={field.field_label}
                                                                    style={{
                                                                        width: '100px',
                                                                        height: 'auto',
                                                                        borderRadius: '4px',
                                                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                                                    }}
                                                                />
                                                            );
                                                        } else {
                                                            return (
                                                                <Typography variant="body2" color="textSecondary">
                                                                    {field.field_value}
                                                                </Typography>
                                                            );
                                                        }
                                                    })()}
                                                </TableCell>
                                                <TableCell>{field.field_placeholder}</TableCell>
                                                <TableCell>{field.field_type}</TableCell>
                                                <TableCell>{field.field_validation}</TableCell>
                                                <TableCell>{field.field_order}</TableCell>
                                                <TableCell>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        {field.field_required && (
                                                            <Tooltip title="Required">
                                                                <CheckCircleIcon
                                                                    color="primary"
                                                                    sx={{
                                                                        fontSize: '1.2rem',
                                                                        opacity: field.field_required ? 1 : 0.3
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                        {field.is_hidden && (
                                                            <Tooltip title="Hidden">
                                                                <VisibilityOffIcon
                                                                    color="warning"
                                                                    sx={{
                                                                        fontSize: '1.2rem',
                                                                        opacity: field.is_hidden ? 1 : 0.3
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                        {field.is_locked && (
                                                            <Tooltip title="Locked">
                                                                <LockIcon
                                                                    color="error"
                                                                    sx={{
                                                                        fontSize: '1.2rem',
                                                                        opacity: field.is_locked ? 1 : 0.3
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    </Stack>
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        aria-controls="simple-menu"
                                                        aria-haspopup="true"
                                                        onClick={(event) => handleMenuClick(event, field.id)}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id="simple-menu"
                                                        anchorEl={anchorEl}
                                                        keepMounted
                                                        open={menuOpen && editFieldId === field.id}
                                                        onClose={handleMenuClose}
                                                    >
                                                        {isAllowedToAccess(accessRole, 'netaji_role', 'manager') &&
                                                            [
                                                                <MenuItem onClick={() => functionFieldEdit(field)}>Edit</MenuItem>
                                                            ]

                                                        }
                                                        {isAllowedToAccess(accessRole, 'netaji_role', 'admin') &&
                                                            <MenuItem onClick={() => functionDelete(field.id)}>Delete</MenuItem>
                                                        }
                                                    </Menu>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <Typography>You don't have any permission</Typography>
            )}

            {/* Add Dialog */}
            <Dialog open={open} onClose={closePopup} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Create Field</Typography>
                    </DialogTitle>
                    <IconButton onClick={closePopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleTemplateFieldSubmit}>
                        <Stack spacing={2} margin={2}>
                            <TextField variant='outlined' value={fieldName} onChange={e => setFieldName(e.target.value)} label="Field Name" />
                            <TextField required variant='outlined' value={fieldOrder} onChange={e => setFieldOrder(e.target.value)} label="Field Order" />
                            <Select
                                variant='outlined'
                                value={fieldType}
                                onChange={e => setFieldType(e.target.value)}
                                label="Field Type"
                            >
                                <MenuItem value="string">String</MenuItem>
                                <MenuItem value="number">Number</MenuItem>
                                <MenuItem value="email">Email</MenuItem>
                                <MenuItem value="mobile">Mobile</MenuItem>
                                <MenuItem value="file">File</MenuItem>
                                <MenuItem value="multi-file">Multi-file</MenuItem>
                                <MenuItem value="option">Option</MenuItem>
                                <MenuItem value="date">Date</MenuItem>
                                <MenuItem value="time">Time</MenuItem>
                            </Select>
                            <TextField variant='outlined' value={fieldLabel} onChange={e => setFieldLabel(e.target.value)} label="Field Label" />
                            <TextField variant='outlined' value={fieldValue} onChange={e => setFieldValue(e.target.value)} label="Field Value" />
                            <TextField variant='outlined' value={fieldValidation} onChange={e => setFieldValidation(e.target.value)} label="Field Validation" />
                            <TextField variant='outlined' value={fieldGroupCode} onChange={e => setFieldGroupCode(e.target.value)} label="Field Group Code" />
                            <FormControlLabel
                                control={<Switch checked={fieldRequired} onChange={e => setFieldRequired(e.target.checked)} />}
                                label="Is Field Required"
                            />
                            <TextField variant='outlined' value={fieldPlaceHolder} onChange={e => setFieldPlaceHolder(e.target.value)} label="Field PlaceHolder" />
                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closePopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Submit</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Edit Dialog */}
            <Dialog
                open={editOpen}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    sx: {
                        borderRadius: 2,
                        boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)'
                    }
                }}
            >
                <DialogTitle sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    bgcolor: 'primary.main',
                    color: 'white',
                    py: 2
                }}>
                    <Typography variant='h5'>Edit Template Field</Typography>
                    <IconButton onClick={closeEditPopup} sx={{ color: 'white' }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ py: 3 }}>
                    <form onSubmit={handleTemplateFieldEditSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={3}>
                                    <TextField
                                        required
                                        fullWidth
                                        value={editFieldName}
                                        onChange={e => setEditFieldName(e.target.value)}
                                        label="Field Name"
                                        InputProps={{
                                            startAdornment: <DriveFileRenameOutlineIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        }}
                                    />

                                    <FormControl fullWidth>
                                        <InputLabel>Field Type</InputLabel>
                                        <Select
                                            value={editFieldType}
                                            onChange={e => setEditFieldType(e.target.value)}
                                            label="Field Type"
                                            startAdornment={<CategoryIcon sx={{ mr: 1, color: 'primary.main' }} />}
                                        >
                                            <MenuItem value="string">String</MenuItem>
                                            <MenuItem value="number">Number</MenuItem>
                                            <MenuItem value="email">Email</MenuItem>
                                            <MenuItem value="mobile">Mobile</MenuItem>
                                            <MenuItem value="file">File</MenuItem>
                                            <MenuItem value="multi-file">Multi-file</MenuItem>
                                            <MenuItem value="option">Option</MenuItem>
                                            <MenuItem value="date">Date</MenuItem>
                                            <MenuItem value="time">Time</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        fullWidth
                                        type="number"
                                        value={editFieldOrder}
                                        onChange={e => setEditFieldOrder(e.target.value)}
                                        label="Field Order"
                                        InputProps={{
                                            startAdornment: <FormatListNumberedIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        }}
                                    />

                                    <TextField
                                        fullWidth
                                        value={editFieldLabel}
                                        onChange={e => setEditFieldLabel(e.target.value)}
                                        label="Field Label"
                                        InputProps={{
                                            startAdornment: <LabelIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        }}
                                    />
                                </Stack>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Stack spacing={3}>
                                    <TextField
                                        fullWidth
                                        value={editFieldValue}
                                        onChange={e => setEditFieldValue(e.target.value)}
                                        label="Field Value"
                                        InputProps={{
                                            startAdornment: <TextFieldsIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        }}
                                    />

                                    <TextField
                                        fullWidth
                                        value={editFieldValidation}
                                        onChange={e => setEditFieldValidation(e.target.value)}
                                        label="Field Validation"
                                        InputProps={{
                                            startAdornment: <RuleIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        }}
                                    />

                                    <TextField
                                        fullWidth
                                        value={editFieldGroupCode}
                                        onChange={e => setEditFieldGroupCode(e.target.value)}
                                        label="Field Group Code"
                                        InputProps={{
                                            startAdornment: <CodeIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        }}
                                    />

                                    <TextField
                                        fullWidth
                                        value={editFieldPlaceholder}
                                        onChange={e => setEditFieldPlaceholder(e.target.value)}
                                        label="Field Placeholder"
                                        InputProps={{
                                            startAdornment: <TextFieldsIcon sx={{ mr: 1, color: 'primary.main' }} />
                                        }}
                                    />
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    gap: 3,
                                    flexWrap: 'wrap',
                                    bgcolor: 'grey.50',
                                    p: 2,
                                    borderRadius: 1
                                }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={editFieldRequired}
                                                onChange={e => setEditFieldRequired(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <CheckCircleIcon color="primary" />
                                                <Typography>Required Field</Typography>
                                            </Stack>
                                        }
                                    />

                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={editIsHidden}
                                                onChange={e => setEditIsHidden(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <VisibilityOffIcon color="primary" />
                                                <Typography>Hidden Field</Typography>
                                            </Stack>
                                        }
                                    />

                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={editIsLocked}
                                                onChange={e => setEditIsLocked(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <LockIcon color="primary" />
                                                <Typography>Locked Field</Typography>
                                            </Stack>
                                        }
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 4 }}>
                            <Button
                                variant="outlined"
                                onClick={closeEditPopup}
                                startIcon={<CloseIcon />}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                type="submit"
                                startIcon={<SaveIcon />}
                            >
                                Update
                            </Button>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Delete Dialog */}
            <Dialog open={deleteOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Delete Field</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeDeletePopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent sx={{ marginTop: 4 }}>
                    <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                        Are you sure you want to delete this field?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button variant="outlined" onClick={closeDeletePopup}>Cancel</Button>
                    <Button variant="contained" color="error" onClick={handleFieldDelete}>Delete</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default FrameTemplateField;
