import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { createPartnerCardSampleData, deletePartnerCardSampleData, getSinglePartnerCardSampleData, updatePartnerCardSampleData } from '../../redux/actions/partnerCardSampleActions';
import Loading from '../../components/Loading';
import { isAllowedToAccess } from '../../components/PermissionManager';

function PartnerCardSample() {

    const dispatch = useDispatch();
    const params = useParams();
    const [id, setId] = useState(params.child);

    console.log("id card sample", params);

    useEffect(() => {
        setId(params.card_id);
    }, [params.card_id, id]);


    const [loading, setLoading] = useState(true);

    const cards = useSelector((state) => state?.cards?.cardsData?.data);
    const partnerCardSample = useSelector((state) => state?.partnerCardSample?.partnerCardSampleData?.data);
    console.log("Partner Card Sample", partnerCardSample);

    // Fetch card sample data on component mount
    useEffect(() => {
        const fetchPartnerCardSample = async () => {
            setLoading(true);

            const cardId = { card_id: id }
            await dispatch(getSinglePartnerCardSampleData(cardId));
            console.log("Single data");
            setLoading(false);
        };

        fetchPartnerCardSample();
    }, [dispatch]);

    const columns = [
        { id: 'card-id', name: 'Id' },
        { id: 'url', name: 'URL' },
        { id: 'thumbnail', name: 'Thumbnail' },
        { id: 'mediaType', name: 'Media Type' },
        { id: 'mediaOrder', name: 'Media Order' },
        { id: 'mediaStatus', name: 'Media Status' },
        { id: 'actions', name: 'Actions' }
    ];

    // Add functionality
    const [open, setOpen] = useState(false);
    const [url, setUrl] = useState('');
    const [thumbnail, setThumbnail] = useState('');
    const [mediaType, setMediaType] = useState('Image');
    const [mediaOrder, setMediaOrder] = useState(50);
    const [mediaStatus, setMediaStatus] = useState('active');


    const functionAdd = () => {
        setOpen(true);
    };

    const closePopup = () => {
        setOpen(false);
    };

    const handlePartnerCardSampleSubmit = async (e) => {
        e.preventDefault();
        const newCards = {
            card_id: params.card_id, url,
            thumbnail, media_type: mediaType, media_order: mediaOrder,
            media_status: mediaStatus,
        }
        const response = await dispatch(createPartnerCardSampleData(newCards));
        console.log("Inside Create Crad sample", response);
        const cardId = { card_id: id }
        await dispatch(getSinglePartnerCardSampleData(cardId));
        setUrl('');
        setThumbnail('');
        setMediaType('');
        setMediaOrder('');
        setMediaStatus('');
        closePopup();
    }



    // Edit functionality
    const [editOpen, setEditOpen] = useState(false);
    const [editSampleId, setEditSampleId] = useState('');
    const [editCardId, setEditCardId] = useState('');
    const [editUrl, setEditUrl] = useState('');
    const [editThumbnail, setEditThumbnail] = useState('');
    const [editMediaType, setEditMediaType] = useState('');
    const [editMediaOrder, setEditMediaOrder] = useState('');
    const [editMediaStatus, setEditMediaStatus] = useState('');

    const functionEdit = useCallback((card) => {
        console.log("inside Edit", card);
        setEditSampleId(card.id);
        setEditCardId(card.card_id);
        setEditUrl(card.url);
        setEditThumbnail(card.thumbnail);
        setEditMediaType(card.media_type);
        setEditMediaOrder(card.media_order);
        setEditMediaStatus(card.media_status);
        setEditOpen(true);
    }, []);

    const closeEditPopup = () => {
        setEditOpen(false);
    };

    const handlePartnerCardSampleEditSubmit = async (e) => {
        e.preventDefault();
        const updatedCards = {
            id: editSampleId, card_id: editCardId, url: editUrl,
            thumbnail: editThumbnail, media_type: editMediaType, media_order: editMediaOrder,
            media_status: editMediaStatus,
        }
        await dispatch(updatePartnerCardSampleData(updatedCards))
        const cardId = { card_id: id }
        await dispatch(getSinglePartnerCardSampleData(cardId));
        closeEditPopup();
    };



    // Delete functionality
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteSampleId, setDeleteSampleId] = useState('');

    const functionDelete = useCallback((id) => {
        setDeleteSampleId(id);
        setDeleteOpen(true);
    }, []);
    const closeDeletePopup = () => {
        setDeleteOpen(false);
    };
    const handlePartnerCardSampleDelete = async () => {
        if (deleteSampleId) {
            await deletePartnerCardSampleData(deleteSampleId);
            const cardId = { card_id: id }
            await dispatch(getSinglePartnerCardSampleData(cardId));
            closeDeletePopup();
        }
    };



    // validation 
    const validateCreateFields = () => {
        return mediaOrder !== '' && !isNaN(mediaOrder)
    };
    const accessRole = useSelector((state) => state?.auth?.user?.user_info?.meta_data);


    return (
        <div>
            {isAllowedToAccess(accessRole, 'partners_role', 'designer') ? (
                <Box >
                    <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: { xs: 'flex-start', md: 'space-between' },
                            width: '100%'
                        }}>
                            <Typography sx={{
                                fontSize: { xs: '20px', sm: '24px', md: '28px' },
                                mb: { xs: 2, md: 0 },
                                textAlign: {}
                            }}>
                                Card Sample
                            </Typography>

                            <Box sx={{
                                display: 'flex',
                                gap: { xs: 1, md: 0.5 },
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: { xs: 'center', md: 'flex-end' },
                                width: { xs: '100%', md: 'auto' },
                                alignItems: 'center'
                            }}>
                                {isAllowedToAccess(accessRole, 'partners_role', 'manager') &&
                                    <Button variant='contained'
                                        sx={{
                                            backgroundColor: 'midnightblue',
                                            textTransform: 'none',
                                            fontSize: { xs: '12px', sm: '14px', md: '16px' },
                                            width: { xs: '100%', sm: 'auto' }
                                        }}
                                        onClick={functionAdd}
                                    >
                                        Add Card Sample
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Toolbar>


                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow >
                                    {columns.map((column) => (
                                        <TableCell key={column.id} >{column.name}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            <Loading />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    partnerCardSample && partnerCardSample?.card_samples?.map((card) => (
                                        <TableRow key={card.id}>
                                            <TableCell>{card.id}</TableCell>
                                            <TableCell>{card.url}</TableCell>
                                            <TableCell>{card.thumbnail}</TableCell>
                                            <TableCell>{card.media_type}</TableCell>
                                            <TableCell>{card.media_order}</TableCell>
                                            <TableCell>{card.media_status}</TableCell>
                                            <TableCell>
                                                {isAllowedToAccess(accessRole, 'partners_role', 'manager') &&
                                                    <Button variant='contained' onClick={() => functionEdit(card)} style={{ backgroundColor: 'green', color: 'white', textTransform: 'none' }}>Edit</Button>
                                                }
                                                {isAllowedToAccess(accessRole, 'partners_role', 'admin') &&
                                                    <Button variant='outlined' onClick={() => functionDelete(card.id)} style={{ color: 'red', textTransform: 'none' }}>Delete</Button>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            ) : (
                <Typography>You don't have any permission</Typography>
            )}

            {/* Add Dialog */}
            <Dialog open={open} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Create Card</Typography>
                    </DialogTitle>
                    <IconButton onClick={closePopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handlePartnerCardSampleSubmit}>
                        <Stack spacing={2} margin={2}>

                            <TextField variant='outlined' value={url} onChange={e => setUrl(e.target.value)} label="URL" />
                            <TextField variant='outlined' value={thumbnail} onChange={e => setThumbnail(e.target.value)} label="Thumbnail" />

                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Media Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Media Type"
                                    value={mediaType}
                                    onChange={e => setMediaType(e.target.value)}
                                >
                                    <MenuItem value="Number">Number</MenuItem>
                                    <MenuItem value="String">String</MenuItem>
                                    <MenuItem value="File">File</MenuItem>
                                    <MenuItem value="Image">Image</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField type='number' required error={isNaN(mediaOrder)} variant='outlined' value={mediaOrder} onChange={e => setMediaOrder(e.target.value)} label="Media Order" />
                            <TextField variant='outlined' value={mediaStatus} onChange={e => setMediaStatus(e.target.value)} label="Media Status" />

                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closePopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} disabled={!validateCreateFields()} type='submit'>Submit</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Edit Dialog */}
            <Dialog open={editOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Edit Card Sample</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeEditPopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handlePartnerCardSampleEditSubmit}>
                        <Stack spacing={2} margin={2}>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Card Id</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Card Id"
                                    value={editCardId}
                                    onChange={e => setEditCardId(e.target.value)}
                                >
                                    {cards && cards?.rows && cards?.rows.length > 0 ? (
                                        cards?.rows.map((card) => (
                                            <MenuItem key={card.id} value={card.id}>{card.card_name}</MenuItem>
                                        ))
                                    ) : (
                                        <Typography variant="body1" color="initial"></Typography>
                                    )}
                                </Select>
                            </FormControl>
                            <TextField required variant='outlined' value={editUrl} onChange={e => setEditUrl(e.target.value)} label="URL" />
                            <TextField variant='outlined' value={editThumbnail} onChange={e => setEditThumbnail(e.target.value)} label="Thumbnail" />
                            <TextField variant='outlined' value={editMediaType} onChange={e => setEditMediaType(e.target.value)} label="Media Type" />
                            <TextField variant='outlined' type='number' value={editMediaOrder} onChange={e => setEditMediaOrder(e.target.value)} label="Media Order" />
                            <TextField variant='outlined' value={editMediaStatus} onChange={e => setEditMediaStatus(e.target.value)} label="Media Status" />
                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeEditPopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Update</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Delete Dialog */}
            <Dialog open={deleteOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Delete Card Sample</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeDeletePopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent sx={{ marginTop: 4 }}>
                    <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                        Are you sure you want to delete this card sample. You have to manually delete the file from server?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button variant="outlined" onClick={closeDeletePopup}>Cancel</Button>
                    <Button variant="contained" color="error" onClick={handlePartnerCardSampleDelete}>Delete</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default PartnerCardSample