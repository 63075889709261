import * as actionTypes from '../actionTypes.js';

const initialState = {
    allTasksData: null,
    loading: false,
    error: null,
};

const tasksReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_TASKS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.GET_ALL_TASKS_SUCCESS:
            return {
                ...state,
                loading: false,
                allTasksData: action.payload,
            };
        case actionTypes.GET_ALL_TASKS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        default:
            return state
    }
};
export default tasksReducer;