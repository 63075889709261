import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { fetchCardEntryData } from '../../redux/actions/cardEntryActions';
import { createCardEntryValueData, deleteCardEntryValueData, fetchCardEntryValueData, updateCardEntryValueData } from '../../redux/actions/cardEntryValuesActions';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { isAllowedToAccess } from '../../components/PermissionManager';

function CradEntryValue() {
    const dispatch = useDispatch();

    const cardEntry = useSelector((state) => state?.cardEntry?.cardEntryData?.data);
    const cardEntryValue = useSelector((state) => state?.cardEntryValue?.cardEntryValueData?.data);
    const [loading, setLoading] = useState(true);


    // Fetch card entry value data on component mount
    useEffect(() => {
        const fetchCardEntryValues = async () => {
            setLoading(true);
            await dispatch(fetchCardEntryData());
            await dispatch(fetchCardEntryValueData());
            setLoading(false);
        };

        fetchCardEntryValues();
    }, [dispatch]);

    const columns = [
        { id: 'entryId', name: 'Id' },
        { id: 'fieldName', name: 'Field Name' },
        { id: 'fieldValue', name: 'Field Value' },
        { id: 'fieldRef', name: 'Field Reference Id' },
        { id: 'actions', name: 'Actions' }
    ];

    // Add functionality
    const [open, setOpen] = useState(false);
    const [entryId, setEntryId] = useState('');
    const [fieldName, setFieldName] = useState('');
    const [fieldValue, setFieldValue] = useState('');
    const [fieldRefId, setFieldRefId] = useState('');



    const functionAdd = () => {
        openPopup();
    };

    const closePopup = () => {
        setOpen(false);
    };

    const openPopup = () => {
        setOpen(true);
    };

    const handleCardEntryValueSubmit = async (e) => {
        e.preventDefault();
        const newEntryValue = {
            entry_id: entryId, field_name: fieldName, field_value: fieldValue,
            field_ref_id: fieldRefId
        }
        const response = await dispatch(createCardEntryValueData(newEntryValue));
        if (response?.status === 'success') {
            toast.success('Card Entry Value submitted successfully!', {
                autoClose: 3000,
            });
        } else {
            toast.error('Failed to submitted card Entry Value. Please try again.', {
                autoClose: 3000,
            });
        }
        await dispatch(fetchCardEntryValueData());
        closePopup();
        setEntryId();
        setFieldName();
        setFieldValue();
        setFieldRefId();
    }

    // Edit functionality
    const [editOpen, setEditOpen] = useState(false);
    const [editValueId, setEditValueId] = useState('');
    const [editEntryId, setEditEntryId] = useState('');
    const [editFieldName, setEditFieldName] = useState('');
    const [editFieldValue, setEditFieldValue] = useState('');
    const [editFieldRefId, setEditFieldRefId] = useState('');

    const functionEdit = useCallback((card) => {
        console.log("inside Edit", card);
        setEditValueId(card.id)
        setEditEntryId(card.entry_id);
        setEditFieldName(card.field_name);
        setEditFieldValue(card.field_value);
        setEditFieldRefId(card.field_ref_id);

        setEditOpen(true);
    }, []);

    const closeEditPopup = () => {
        setEditOpen(false);
    };

    const handleCardEntryValueEditSubmit = async (e) => {
        e.preventDefault();
        const updatedCards = {
            id: editValueId, entry_id: editEntryId, field_name: editFieldName,
            field_value: editFieldValue, field_ref_id: editFieldRefId
        }
        const response = await dispatch(updateCardEntryValueData(updatedCards));
        if (response?.payload?.status === 'success') {


            toast.success('Card Entry Value updated successfully!', {
                autoClose: 3000, // Auto-close after 3 seconds
            });
        } else {
            toast.error('Failed to update Card Entry Value. Please try again.', {
                autoClose: 3000,
            });
        }
        await dispatch(fetchCardEntryValueData());
        closeEditPopup();
    };



    // Delete functionality
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteValueId, setDeleteValueId] = useState('');

    const functionDelete = useCallback((id) => {
        setDeleteValueId(id);
        setDeleteOpen(true);
    }, []);
    const closeDeletePopup = () => {
        setDeleteOpen(false);
    };
    const handleCardEntryValueDelete = async () => {
        if (deleteValueId) {
            const response = await dispatch(deleteCardEntryValueData(deleteValueId));
            if (response?.status === 200) {
                toast.success('Card Deleted successfully!', {
                    autoClose: 3000,
                });
            } else {
                toast.error('Failed to Deleted card. Please try again.', {
                    autoClose: 3000,
                });
            }
            await dispatch(fetchCardEntryValueData());
            closeDeletePopup();
        }
    };

    const accessRole = useSelector((state) => state?.auth?.user?.user_info?.meta_data);

    return (
        <>
            {isAllowedToAccess(accessRole, 'mainwebsite_role', 'designer') ? (
                <>
                    <Box position="static" sx={{ borderRadius: 3, color: 'black' }}>
                        <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: { xs: 'flex-start', md: 'space-between' },
                                width: '100%'
                            }}>
                                <Typography sx={{
                                    fontSize: { xs: '20px', sm: '24px', md: '28px' },
                                    mb: { xs: 2, md: 0 },
                                    textAlign: {}
                                }}>
                                    Card Entry Value
                                </Typography>

                                <Box sx={{
                                    display: 'flex',
                                    gap: { xs: 1, md: 0.5 },
                                    flexDirection: { xs: 'column', md: 'row' },
                                    justifyContent: { xs: 'center', md: 'flex-end' },
                                    width: { xs: '100%', md: 'auto' },
                                    alignItems: 'center'
                                }}>
                                    {isAllowedToAccess(accessRole, 'mainwebsite_role', 'manager') &&
                                        <Button
                                            variant='contained'
                                            sx={{
                                                backgroundColor: 'midnightblue',
                                                textTransform: 'none',
                                                fontSize: { xs: '12px', sm: '14px', md: '16px' },
                                                width: { xs: '100%', sm: 'auto' }
                                            }}
                                            onClick={functionAdd}
                                        >
                                            Add Card Entry Value
                                        </Button>
                                    }
                                </Box>
                            </Box>
                        </Toolbar>
                    </Box>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow >
                                    {columns.map((column) => (
                                        <TableCell key={column.id} >{column.name}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            <Loading />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    cardEntryValue && cardEntryValue?.rows.map((card) => (
                                        <TableRow key={card.id}>
                                            <TableCell>{card.id}</TableCell>
                                            <TableCell>{card.field_name}</TableCell>
                                            <TableCell>{card.field_value}</TableCell>
                                            <TableCell>{card.field_ref_id}</TableCell>
                                            <TableCell>
                                                {isAllowedToAccess(accessRole, 'mainwebsite_role', 'manager') &&
                                                    <Button variant='contained' onClick={() => functionEdit(card)} style={{ backgroundColor: 'green', color: 'white', textTransform: 'none' }}>Edit</Button>
                                                }
                                                {isAllowedToAccess(accessRole, 'mainwebsite_role', 'admin') &&
                                                    <Button variant='outlined' onClick={() => functionDelete(card.id)} style={{ color: 'red', textTransform: 'none' }}>Delete</Button>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <Typography>You don't have any permission</Typography>
            )}

            {/* Add Dialog */}
            <Dialog open={open} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Create Card Entry</Typography>
                    </DialogTitle>
                    <IconButton onClick={closePopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleCardEntryValueSubmit}>
                        <Stack spacing={2} margin={2}>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Card Id</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Group Id"
                                    value={entryId}
                                    onChange={e => setEntryId(e.target.value)}
                                >
                                    {cardEntry && cardEntry?.rows && cardEntry?.rows.length > 0 ? (
                                        cardEntry?.rows.map((card) => (
                                            <MenuItem key={card.id} value={card.id}>{card.title}</MenuItem>
                                        ))
                                    ) : (
                                        <Typography variant="body1" color="initial"></Typography>
                                    )}
                                </Select>
                            </FormControl>
                            <TextField variant='outlined' value={fieldName} onChange={e => setFieldName(e.target.value)} label="Field Name" />
                            <TextField type='number' required error={isNaN(fieldValue)} variant='outlined' value={fieldValue} onChange={e => setFieldValue(e.target.value)} label="Field Value" />
                            <TextField variant='outlined' value={fieldRefId} onChange={e => setFieldRefId(e.target.value)} label="Field Reference Id" />
                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closePopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Submit</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Edit Dialog */}
            <Dialog open={editOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Edit Card Entry Value</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeEditPopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleCardEntryValueEditSubmit}>
                        <Stack spacing={2} margin={2}>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Entry Id</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Group Id"
                                    value={editEntryId}
                                    onChange={e => setEditEntryId(e.target.value)}
                                >
                                    {cardEntry && cardEntry?.rows && cardEntry?.rows.length > 0 ? (
                                        cardEntry?.rows.map((card) => (
                                            <MenuItem key={card.id} value={card.id}>{card.title}</MenuItem>
                                        ))
                                    ) : (
                                        <Typography variant="body1" color="initial"></Typography>
                                    )}
                                </Select>
                            </FormControl>
                            <TextField required variant='outlined' value={editFieldName} onChange={e => setEditFieldName(e.target.value)} label="Field Name" />
                            <TextField variant='outlined' type='number' value={editFieldValue} onChange={e => setEditFieldValue(e.target.value)} label="Field Value" />
                            <TextField variant='outlined' value={editFieldRefId} onChange={e => setEditFieldRefId(e.target.value)} label="Field Reference Id" />

                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeEditPopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Update</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Delete Dialog */}
            <Dialog open={deleteOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Delete Card</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeDeletePopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent sx={{ marginTop: 4 }}>
                    <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                        Are you sure you want to delete this card group?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button variant="outlined" onClick={closeDeletePopup}>Cancel</Button>
                    <Button variant="contained" color="error" onClick={handleCardEntryValueDelete}>Delete</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CradEntryValue;
