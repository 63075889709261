import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Menu, MenuItem, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from 'react-router-dom';
import { createPartnerCardGroupData, fetchPartnerCardGroup, updatePartnerCardGroupData } from '../../redux/actions/partnerCardGroupActions';
import Loading from '../../components/Loading';
import { isAllowedToAccess } from '../../components/PermissionManager';


function PartnerCardGroup() {


    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenuClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setEditGroupId(id);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setEditGroupId(null);
    };


    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    //pagination

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState('');
    const limit = 50;

    useEffect(() => {

    }, [page]);

    const handlePageChange = async (event, value) => {
        console.log("value", value);
        await setPage(value);
        await dispatch(fetchPartnerCardGroup(value, limit));
    };

    const handleSearch = async () => {
        setPage(1);
        setLoading(true);
        await dispatch(fetchPartnerCardGroup(1, limit, search));
        setLoading(false);
    };

    const partnerCardsGroup = useSelector((state) => state?.partnerCardsGroup?.partnerCardGroupData?.data?.rows);


    console.log("partner card group", partnerCardsGroup);
    useEffect(() => {
        const fetchPartnerCardsGroupData = async () => {
            setLoading(true);
            let rsp = await dispatch(fetchPartnerCardGroup(page, limit));
            let total = rsp?.data?.total;
            let pages = Math.ceil(total / Number(limit));
            const pageValue = isNaN(pages) ? Number(pages) : parseFloat(pages);
            if (!isNaN(pageValue)) {
                setTotalPages(pages);
            }

            setLoading(false);

        };
        fetchPartnerCardsGroupData();
    }, [dispatch, page, limit, search]);

    const columns = [
        { id: 'group-id', name: 'Group Id' },
        { id: 'name', name: 'Group Name' },
        { id: 'description', name: 'Group Description' },
        { id: 'order', name: 'Group Order' },
        { id: 'type', name: 'Group Type' },
        { id: 'path', name: 'Group Path' },
        { id: 'status', name: 'Group Status' },
        { id: 'actions', name: 'Actions' }
    ];

    // Add functionality
    const [open, setOpen] = useState(false);
    const [groupName, setGroupName] = useState('');
    const [groupDescription, setGroupDescription] = useState('');
    const [groupOrder, setGroupOrder] = useState('');
    const [groupType, setGroupType] = useState('');
    const [groupPath, setGroupPath] = useState('');

    const functionOpenAdd = () => {
        setOpen(true);
    };

    const closePopup = () => {
        setOpen(false);
    };

    const handlePartnerCradGroupSubmit = async (e) => {
        e.preventDefault();
        const newCardsGroupData = {
            group_name: groupName,
            group_description: groupDescription,
            group_order: groupOrder,
            group_type: groupType,
            group_path: groupPath
        };
        await createPartnerCardGroupData(newCardsGroupData);
        await dispatch(fetchPartnerCardGroup(page, limit));
        closePopup();
    };

    // Edit functionality 

    const [editOpen, setEditOpen] = useState(false);
    const [editGroupId, setEditGroupId] = useState('');
    const [editGroupName, setEditGroupName] = useState('');
    const [editGroupDesc, setEditGroupDesc] = useState('');
    const [editGroupOrder, setEditGroupOrder] = useState('');
    const [editGroupType, setEditGroupType] = useState('');
    const [editGroupPath, setEditGroupPath] = useState('');

    const functionEdit = useCallback((card) => {
        setEditGroupId(card.id);
        setEditGroupName(card.group_name);
        setEditGroupDesc(card.group_description);
        setEditGroupOrder(card.group_order);
        setEditGroupType(card.group_type);
        setEditGroupPath(card.group_path);
        setEditOpen(true);
    }, []);

    const closeEditPopup = () => {
        setEditOpen(false);
    };

    const handlePartnerCardGroupEditSubmit = async (e) => {
        e.preventDefault();
        const updateCardGroup = {
            id: editGroupId, group_name: editGroupName, group_order: editGroupOrder,
            group_type: editGroupType, group_description: editGroupDesc, group_path: editGroupPath
        }

        await dispatch(updatePartnerCardGroupData(updateCardGroup));
        await dispatch(fetchPartnerCardGroup(page, limit));
        closeEditPopup();
    };


    // validation 
    const validateCreateFields = () => {
        return groupOrder !== '' && !isNaN(groupOrder);
    };

    const navigate = useNavigate();

    const handleNavigation = (id) => {
        const queryParams = new URLSearchParams({
            group_id: id,
        }).toString();
        navigate(`/cards/partner-card-group/partner-card?${queryParams}`);
    }

    const accessRole = useSelector((state) => state?.auth?.user?.user_info?.meta_data);

    return (
        <div>
            {isAllowedToAccess(accessRole, 'partners_role', 'designer') ? (
                <>
                    <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: { xs: 'flex-start', md: 'space-between' }
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                            }}>
                                <Typography sx={{
                                    fontSize: { xs: '20px', sm: '24px', md: '28px' },
                                    mb: { xs: 2, md: 0 }
                                }}>
                                    Partner Card Group
                                </Typography>
                                <TextField
                                    size="small"
                                    placeholder="Search"
                                    sx={{
                                        width: { xs: '100%', sm: '200px' },
                                        borderRadius: '20px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '20px'
                                        }
                                    }}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={handleSearch}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                gap: { xs: 1, md: 0.5 },
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: { xs: 'center', md: 'flex-end' },
                                width: { xs: '100%', md: 'auto' },
                                alignItems: 'center'
                            }}>
                                {isAllowedToAccess(accessRole, 'partners_role', 'manager') &&
                                    <Button
                                        variant='contained'
                                        sx={{
                                            backgroundColor: 'midnightblue',
                                            textTransform: 'none',
                                            fontSize: { xs: '12px', sm: '14px', md: '16px' },
                                            width: { xs: '100%', sm: 'auto' }
                                        }}
                                        onClick={functionOpenAdd}
                                    >
                                        Add Card Group
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Toolbar>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell key={column.id}>{column.name}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            <Loading />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <>
                                        {Array.isArray(partnerCardsGroup) && partnerCardsGroup.map((row) => (

                                            <TableRow key={row.id}>
                                                <TableCell>{row.id}</TableCell>
                                                <TableCell>{row.group_name}</TableCell>
                                                <TableCell>{row.group_description}</TableCell>
                                                <TableCell>{row.group_order}</TableCell>
                                                <TableCell>{row.group_type}</TableCell>
                                                <TableCell>{row.group_path}</TableCell>
                                                <TableCell>{row.group_status}</TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        aria-controls="simple-menu"
                                                        aria-haspopup="true"
                                                        onClick={(event) => handleMenuClick(event, row.id)}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id="simple-menu"
                                                        anchorEl={anchorEl}
                                                        keepMounted
                                                        open={menuOpen && editGroupId === row.id}
                                                        onClose={handleMenuClose}
                                                    >
                                                        {isAllowedToAccess(accessRole, 'partners_role', 'manager') && [
                                                            <MenuItem key="edit" onClick={() => functionEdit(row)}>
                                                                Edit
                                                            </MenuItem>,
                                                            <MenuItem key="cards" onClick={() => handleNavigation(row?.id)}>
                                                                Cards
                                                            </MenuItem>
                                                        ]}
                                                        {isAllowedToAccess(accessRole, 'partners_role', 'admin') && (
                                                            <MenuItem key="delete">
                                                                Delete
                                                            </MenuItem>
                                                        )}
                                                    </Menu>

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                )}

                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* pagination */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1%' }}>
                        <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
                    </Box>
                </>
            ) : (
                <Typography>You don't have any permission</Typography>
            )}

            {/* Add card Group Dialog */}
            <Dialog open={open} onClose={closePopup}>
                <DialogTitle>
                    Add Card Group
                    <IconButton onClick={closePopup}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handlePartnerCradGroupSubmit}>
                        <TextField
                            label="Group Name"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Group Description"
                            value={groupDescription}
                            onChange={(e) => setGroupDescription(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            type='number'
                            required
                            label="Group Order"
                            value={groupOrder}
                            error={isNaN(groupOrder)}
                            onChange={(e) => setGroupOrder(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Group Type"
                            value={groupType}
                            onChange={(e) => setGroupType(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Group Path"
                            value={groupPath}
                            onChange={(e) => setGroupPath(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <DialogActions>
                            <Button onClick={closePopup} color="primary">Cancel</Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={!validateCreateFields()}
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>

            <Dialog open={editOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Edit Card Group</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeEditPopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handlePartnerCardGroupEditSubmit}>
                        <Stack spacing={2} margin={2}>

                            <TextField required variant='outlined' value={editGroupName} onChange={e => setEditGroupName(e.target.value)} label="Group Name" />
                            <TextField required variant='outlined' value={editGroupDesc} onChange={e => setEditGroupDesc(e.target.value)} label="Group Description" />
                            <TextField required variant='outlined' value={editGroupOrder} onChange={e => setEditGroupOrder(e.target.value)} label="Group Order" />
                            <TextField required variant='outlined' value={editGroupType} onChange={e => setEditGroupType(e.target.value)} label="Group Type" />
                            <TextField required variant='outlined' value={editGroupPath} onChange={e => setEditGroupPath(e.target.value)} label="Group Path" />


                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeEditPopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Update</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>



        </div>
    )
}

export default PartnerCardGroup