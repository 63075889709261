import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const getCustomGroupRequest = () => ({
    type: actionTypes.GET_ALL_CUSTOM_GROUP_REQUEST,
});

export const getCustomGroupSuccess = (data) => ({
    type: actionTypes.GET_ALL_CUSTOM_GROUP_SUCCESS,
    payload: data,
});
export const getCustomGroupFailure = (error) => ({
    type: actionTypes.GET_ALL_CUSTOM_GROUP_FAILURE,
    payload: error,
});

export const getCustomGroupData = () => {
    return async(dispatch) => {
        dispatch(getCustomGroupRequest());
        try{
            const data = await api.getCustomGroup();
            
            dispatch(getCustomGroupSuccess(data));
            return data;
        }catch(error){
            dispatch(getCustomGroupFailure());
        }
    }
};

export const createCustomGroupData = async(formData) => {

    const data = await api.createCustomGroup(formData);
    return data;
};

export const updateCustomGroupData = async (formData) => {
    const data = await api.updateCustomGroup(formData.id, formData);
    return data;
};
export const deleteCustomGroupData = async (id) => {
    const data = await api.deleteCustomGroup(id);
    return data;
}
