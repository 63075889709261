import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, Menu, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography, InputAdornment, Pagination, Link, Card, Chip, Tooltip, ListItemIcon, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate, useLocation } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Loading from '../../components/Loading';
import { isAllowedToAccess } from '../../components/PermissionManager';
import { 
    createFrameEntryData, 
    deleteFrameEntryData, 
    downloadFrameEntryData, 
    editFrameEntryData, 
    getFrameEntryData, 
    paymentResyncFrameEntryData, 
    regenerateFrameEntryData, 
    suspendFrameEntryData 
} from '../../redux/actions/frameEntryActions';
import { fetchAllTemplateData } from '../../redux/actions/templateActions';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteIcon from '@mui/icons-material/Delete';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import VerifiedIcon from '@mui/icons-material/Verified';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
    getFrameEntryValue,
    createFrameEntryValue,
    updateFrameEntryValue,
    deleteFrameEntryValue
} from '../../api';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

function FrameEntry() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const cards = useSelector((state) => state?.cards?.cardsData?.data);
    const frameEntry = useSelector((state) => state?.frameEntry?.frameEntryData?.data?.data);
    const allTemplate = useSelector((state) => state?.template?.templateData?.data?.data?.templates || []);

    console.log("all template", allTemplate);
    const [loading, setLoading] = useState(true);

    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenuClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setEditEntryId(id);
        setDeleteEntryeId(id);
        setEditRegenerateEntryId(id);
        setEditPaymentResyncEntryId(id);
        setEditDownloadEntryId(id);
        setEditSuspendEntryId(id);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setEditEntryId(null);
        setDeleteEntryeId(null);
        setEditRegenerateEntryId(null);
        setEditPaymentResyncEntryId(null);
        setEditDownloadEntryId(null);
        setEditSuspendEntryId(null);
    };

    // pagination 

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(50);

    useEffect(() => {
        getFrameEntryData(page, limit);
    }, [page]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const urlSearch = searchParams.get('search');
        if (urlSearch) {
            setSearch(urlSearch);
        }
    }, [location.search]);

    const handlePageChange = async (event, value) => {
        console.log("value", value);
        await setPage(value);
        await dispatch(getFrameEntryData(value, limit));
    };

    const handleSearch = async () => {
        setPage(1);
        setLoading(true);
        await dispatch(getFrameEntryData(1, limit, search));
        setLoading(false);
    };



    // Fetch card Entry data on component mount
    useEffect(() => {
        const fetchFrameEntry = async () => {
            setLoading(true);
            await dispatch(fetchAllTemplateData(page, limit, search));
            let rsp = await dispatch(getFrameEntryData(page, limit, search));
            console.log("rsp in frame entry", rsp);
            let total = rsp?.data?.data?.pagination?.total;
            let pages = Math.ceil(total / Number(limit));
            setTotalPages(pages);
            setLoading(false);
        };

        fetchFrameEntry();
    }, [dispatch, page, limit, search]);

    const columns = [
        { id: 'entryId', name: 'Id' },
        { id: 'title', name: 'Title' },
        { id: 'image', name: 'Image' },
        { id: 'status', name: 'Status' },
        { id: 'download', name: 'Download' },
        { id: 'actions', name: 'Actions' }
    ];

    // Add functionality
    const [open, setOpen] = useState(false);
    const [templateId, setTemplateId] = useState('');
    const [entryTitle, setEntryTitle] = useState('');
    const [userId, setUserid] = useState('');



    const functionAdd = () => {
        openPopup();
    };

    const closePopup = () => {
        setOpen(false);
    };

    const openPopup = () => {
        setOpen(true);
    };

    const handleFrameEntrySubmit = async (e) => {
        e.preventDefault();
        const newCards = {
            frame_template_id: templateId, user_id: userId, title: entryTitle
        }
        const response = await createFrameEntryData(newCards);

        // if (response?.status === 'success') {
        //     toast.success('Card Entry submitted successfully!', {
        //         autoClose: 3000,
        //     });
        // } else {
        //     toast.error('Failed to submitted card Entry. Please try again.', {
        //         autoClose: 3000,
        //     });
        // }
        await dispatch(getFrameEntryData(page, limit, search));
        closePopup();
        setTemplateId('');
        setEntryTitle('');
        setUserid('');

    }

    // Edit functionality
    const [editOpen, setEditOpen] = useState(false);
    const [editEntryId, setEditEntryId] = useState('');
    const [editTitle, setEditTitle] = useState('');
    const [editTemplateId, setEditTemplateId] = useState('');

    const functionEdit = useCallback((frame) => {
        console.log("inside Edit", frame);
        setEditEntryId(frame.id);
        setEditTitle(frame.title);
        setEditTemplateId(frame.frame_template_id);
        setEditOpen(true);
    }, []);

    const closeEditPopup = () => {
        setEditOpen(false);
    };

    const handleFrameEntryEditSubmit = async (e) => {
        e.preventDefault();
        const updatedCards = {
            id: editEntryId, title: editTitle, frame_template_id: editTemplateId
        }
        const response = await editFrameEntryData(updatedCards);
        console.log("update card entry response", response);
        // if (response?.payload?.status === 'success') {


        //     toast.success('Card Entry updated successfully!', {
        //         autoClose: 3000, // Auto-close after 3 seconds
        //     });
        // } else {
        //     toast.error('Failed to update card Entry. Please try again.', {
        //         autoClose: 3000,
        //     });
        // }
        await dispatch(getFrameEntryData(page, limit, search));
        closeEditPopup();
        handleMenuClose();
    };

    //Regenerate
    const [editRegenerateOpen, setEditRegenerateOpen] = useState(false);
    const [editRegenerateEntryId, setEditRegenerateEntryId] = useState('');


    const functionRegenerate = useCallback((frame) => {
        console.log("inside Edit", frame);
        setEditRegenerateEntryId(frame.id);

        setEditRegenerateOpen(true);
    }, []);

    const closeEditRegeneratePopup = () => {
        setEditRegenerateOpen(false);
    };

    const handleFrameEntryRegenerateSubmit = async (e) => {
        e.preventDefault();
        const updatedCards = {
            id: editEntryId
        }
        const response = await regenerateFrameEntryData(updatedCards);
        console.log("update card entry response", response);
        // if (response?.payload?.status === 'success') {


        //     toast.success('Card Entry updated successfully!', {
        //         autoClose: 3000, // Auto-close after 3 seconds
        //     });
        // } else {
        //     toast.error('Failed to update card Entry. Please try again.', {
        //         autoClose: 3000,
        //     });
        // }
        await dispatch(getFrameEntryData(page, limit, search));
        closeEditPopup();
        handleMenuClose();
    };

    //payment resync
    const [editPaymentResyncOpen, setEditPaymentResyncOpen] = useState(false);
    const [editPaymentResyncEntryId, setEditPaymentResyncEntryId] = useState('');


    const functionPaymentResync = useCallback((frame) => {
        console.log("inside Edit", frame);
        setEditPaymentResyncEntryId(frame.id);

        setEditPaymentResyncOpen(true);
    }, []);

    const closeEditPaymentResyncPopup = () => {
        setEditPaymentResyncOpen(false);
    };

    const handleFrameEntryPaymentResyncSubmit = async (e) => {
        e.preventDefault();
        const updatedCards = {
            id: editEntryId
        }
        const response = await paymentResyncFrameEntryData(updatedCards);
        console.log("update card entry response", response);
        // if (response?.payload?.status === 'success') {


        //     toast.success('Card Entry updated successfully!', {
        //         autoClose: 3000, // Auto-close after 3 seconds
        //     });
        // } else {
        //     toast.error('Failed to update card Entry. Please try again.', {
        //         autoClose: 3000,
        //     });
        // }
        await dispatch(getFrameEntryData(page, limit, search));
        closeEditPopup();
        handleMenuClose();
    };


    //Download
    const [editDownloadOpen, setEditDownloadOpen] = useState(false);
    const [editDownloadEntryId, setEditDownloadEntryId] = useState('');


    const functionDownload = useCallback((frame) => {
        console.log("inside Edit", frame);
        setEditDownloadEntryId(frame.id);

        setEditDownloadOpen(true);
    }, []);

    const closeEditDownloadPopup = () => {
        setEditDownloadOpen(false);
    };

    const handleFrameEntryDownloadSubmit = async (e) => {
        e.preventDefault();
        const updatedCards = {
            id: editEntryId
        }
        const response = await downloadFrameEntryData(updatedCards);
        console.log("update card entry response", response);
        // if (response?.payload?.status === 'success') {


        //     toast.success('Card Entry updated successfully!', {
        //         autoClose: 3000, // Auto-close after 3 seconds
        //     });
        // } else {
        //     toast.error('Failed to update card Entry. Please try again.', {
        //         autoClose: 3000,
        //     });
        // }
        await dispatch(getFrameEntryData(page, limit, search));
        closeEditPopup();
        handleMenuClose();
    };

    const [editSuspendOpen, setEditSuspendOpen] = useState(false);
    const [editSuspendEntryId, setEditSuspendEntryId] = useState('');


    const functionSuspend = useCallback((frame) => {
        console.log("inside Edit", frame);
        setEditSuspendEntryId(frame.id);

        setEditSuspendOpen(true);
    }, []);

    const closeEditSuspendPopup = () => {
        setEditSuspendOpen(false);
    };

    const handleFrameEntrySuspendSubmit = async (e) => {
        console.log("inside suspend");
        e.preventDefault();
        const updatedCards = {
            id: editEntryId
        }
        const response = await suspendFrameEntryData(updatedCards);
        console.log("update card entry response", response);
        // if (response?.payload?.status === 'success') {


        //     toast.success('Card Entry updated successfully!', {
        //         autoClose: 3000, // Auto-close after 3 seconds
        //     });
        // } else {
        //     toast.error('Failed to update card Entry. Please try again.', {
        //         autoClose: 3000,
        //     });
        // }
        await dispatch(getFrameEntryData(page, limit, search));
        closeEditPopup();
        handleMenuClose();
    };



    // Delete functionality
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteEntryId, setDeleteEntryeId] = useState('');

    const functionDelete = useCallback((id) => {
        setDeleteEntryeId(id);
        setDeleteOpen(true);
    }, []);
    const closeDeletePopup = () => {
        setDeleteOpen(false);
    };
    const handleFrameEntryDelete = async () => {
        if (deleteEntryId) {
            const response = await deleteFrameEntryData(deleteEntryId);
            console.log("deleted frame entry response", response);
            // if (response?.status === 200) {
            //     toast.success('Card Deleted successfully!', {
            //         autoClose: 3000,
            //     });
            // } else {
            //     toast.error('Failed to Deleted card. Please try again.', {
            //         autoClose: 3000,
            //     });
            // }

            await dispatch(getFrameEntryData(page, limit, search));
            closeDeletePopup();
            handleMenuClose();
        }
    };

    const handleDownload = async (card) => {
        if (!card?.final_url) return;

        try {
            const response = await fetch(card.final_url);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            const urlParts = card.final_url.split('.');
            const extension = urlParts[urlParts.length - 1];

            const filename = card.title + `final.${extension}`;

            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Download failed:", error);
        }
    };

    const getFileType = (url) => {
        if (!url) return 'Unknown';

        const extension = url.split('.').pop().toLowerCase();

        switch (extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return 'image';
            case 'mp4':
            case 'avi':
            case 'mov':
            case 'wmv':
                return 'video';
            case 'pdf':
                return 'pdf';
            default:
                return 'Unknown';
        }
    };

    // Function to format the date in Indian format with time
    const formatDate = (dateString) => {
        if (!dateString) return '';

        const date = new Date(dateString);
        return date.toLocaleString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        });
    };

    const accessRole = useSelector((state) => state?.auth?.user?.user_info?.meta_data);

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        toast.success('Copied to clipboard!', { autoClose: 2000 });
    };

    const handleUserSearch = (userId) => {
        window.open(`/frame-entry?search=${userId}`, '_blank');
    };

    const formatId = (id) => {
        return id?.toString().length > 8 
            ? `${id.toString().substring(0, 4)}...${id.toString().slice(-4)}`
            : id;
    };

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedFrameId, setSelectedFrameId] = useState(null);

    const handleDeleteClick = (frameId) => {
        setSelectedFrameId(frameId);
        setDeleteDialogOpen(true);
        handleMenuClose();
    };

    const handleDeleteConfirm = async () => {
        try {
            await deleteFrameEntryData(selectedFrameId);
            toast.success('Frame entry deleted successfully');
            await dispatch(getFrameEntryData(page, limit, search));
        } catch (error) {
            toast.error('Failed to delete frame entry');
        } finally {
            setDeleteDialogOpen(false);
            setSelectedFrameId(null);
        }
    };

    // Add new state for image preview
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const handlePreviewOpen = (imageUrl) => {
        setPreviewImage(imageUrl);
        setPreviewOpen(true);
    };

    const handlePreviewClose = () => {
        setPreviewOpen(false);
        setPreviewImage('');
    };

    // Add new state for view dialog
    const [viewDialogOpen, setViewDialogOpen] = useState(false);
    const [selectedFrame, setSelectedFrame] = useState(null);
    const [fullScreenImage, setFullScreenImage] = useState(null);

    const handleViewClick = async (frame) => {
        setSelectedFrame(frame);
        await fetchEntryValues(frame.id);
        setViewDialogOpen(true);
        handleMenuClose();
    };

    const handleImageClick = (imageUrl) => {
        setFullScreenImage(imageUrl);
    };

    // Add new states for frame entry values
    const [entryValues, setEntryValues] = useState([]);
    const [valueDialogOpen, setValueDialogOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [valueFormData, setValueFormData] = useState({
        field_id: '',
        value: ''
    });

    const fetchEntryValues = async (entryId) => {
        try {
            const response = await getFrameEntryValue(entryId);
            setEntryValues(response.data.data);
        } catch (error) {
            toast.error('Failed to fetch entry values');
        }
    };

    const handleValueDialogOpen = (value = null) => {
        setSelectedValue(value);
        if (value) {
            setValueFormData({
                field_id: value.field_id,
                value: value.value
            });
        } else {
            setValueFormData({
                field_id: '',
                value: ''
            });
        }
        setValueDialogOpen(true);
    };

    const handleValueSubmit = async () => {
        try {
            if (selectedValue) {
                await updateFrameEntryValue(selectedFrame.id, selectedValue.id, valueFormData);
                toast.success('Value updated successfully');
            } else {
                await createFrameEntryValue(selectedFrame.id, valueFormData);
                toast.success('Value added successfully');
            }
            await fetchEntryValues(selectedFrame.id);
            setValueDialogOpen(false);
        } catch (error) {
            toast.error(selectedValue ? 'Failed to update value' : 'Failed to add value');
        }
    };

    const handleValueDelete = async (valueId) => {
        try {
            await deleteFrameEntryValue(selectedFrame.id, valueId);
            toast.success('Value deleted successfully');
            await fetchEntryValues(selectedFrame.id);
        } catch (error) {
            toast.error('Failed to delete value');
        }
    };

    return (
        <Box sx={{ p: { xs: 1, sm: 2, md: 3 } }}>
            {/* Header Section */}
            <Box 
                sx={{ 
                    mb: 3,
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'stretch', sm: 'center' },
                    gap: 2,
                    justifyContent: 'space-between'
                }}
            >
                <Box>
                    <Typography 
                        variant="h5" 
                        sx={{ 
                            fontWeight: 600,
                            mb: { xs: 1, sm: 0 }
                        }}
                    >
                        Frame Entry
                    </Typography>
                </Box>
                
                <Box 
                    sx={{ 
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: 2 
                    }}
                >
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <TextField
                            size="small"
                            placeholder="Search entries..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}
                            sx={{
                                minWidth: { xs: '100%', sm: 220 },
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 2,
                                    bgcolor: 'white'
                                }
                            }}
                            InputProps={{
                                startAdornment: <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={handleSearch}
                            sx={{
                                minWidth: 'auto',
                                px: 2,
                                borderRadius: 2,
                                bgcolor: 'primary.dark',
                                '&:hover': {
                                    bgcolor: 'primary.main'
                                }
                            }}
                        >
                            <SearchIcon />
                        </Button>
                    </Box>
                    
                    {isAllowedToAccess(accessRole, 'netaji_role', 'manager') && (
                        <Button
                            variant="contained"
                            onClick={functionAdd}
                            startIcon={<AddIcon />}
                            sx={{
                                bgcolor: 'primary.dark',
                                borderRadius: 2,
                                textTransform: 'none',
                                boxShadow: 2,
                                '&:hover': {
                                    bgcolor: 'primary.main'
                                }
                            }}
                        >
                            Add Frame Entry
                        </Button>
                    )}
                </Box>
            </Box>

            {/* Table Section */}
            <Card 
                elevation={0} 
                sx={{ 
                    borderRadius: 3,
                    overflow: 'hidden',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }}
            >
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ bgcolor: 'primary.main' }}>
                                {columns.map((column) => (
                                    <TableCell 
                                        key={column.id}
                                        sx={{ 
                                            color: 'white',
                                            fontWeight: 600,
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        {column.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length} align="center">
                                        <Loading />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                frameEntry?.entries?.map((frame) => (
                                    <TableRow 
                                        key={frame?.id}
                                        sx={{ 
                                            '&:hover': { 
                                                bgcolor: 'action.hover' 
                                            }
                                        }}
                                    >
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Typography variant="body2">
                                                    {formatId(frame?.id)}
                                                </Typography>
                                                <Tooltip title="Copy ID">
                                                    <IconButton 
                                                        size="small"
                                                        onClick={() => handleCopyToClipboard(frame?.id)}
                                                    >
                                                        <ContentCopyIcon sx={{ fontSize: 16 }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Stack spacing={1}>
                                                <Typography variant="subtitle2" fontWeight={600}>
                                                    {frame?.title}
                                                </Typography>
                                                <Typography variant="caption" color="text.secondary">
                                                    Created: {formatDate(frame?.createdAt)}
                                                </Typography>
                                                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                                    <Chip 
                                                        size="small" 
                                                        label={`Payment: ${frame?.subscription_status}`}
                                                        color={frame?.subscription_status === 'paid' ? 'success' : 'default'}
                                                    />
                                                    <Chip 
                                                        size="small" 
                                                        label={`Type: ${getFileType(frame?.final_url)}`}
                                                        variant="outlined"
                                                    />
                                                </Box>
                                                <Box sx={{ 
                                                    display: 'flex', 
                                                    alignItems: 'center', 
                                                    gap: 1,
                                                    flexWrap: 'wrap' 
                                                }}>
                                                    <Box sx={{ 
                                                        display: 'flex', 
                                                        alignItems: 'center', 
                                                        gap: 0.5 
                                                    }}>
                                                        <Typography variant="caption" color="text.secondary">
                                                            User ID: {formatId(frame?.user_id)}
                                                        </Typography>
                                                        <Tooltip title="Copy User ID">
                                                            <IconButton 
                                                                size="small"
                                                                onClick={() => handleCopyToClipboard(frame?.user_id)}
                                                            >
                                                                <ContentCopyIcon sx={{ fontSize: 14 }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Search User Entries">
                                                            <IconButton 
                                                                size="small"
                                                                onClick={() => handleUserSearch(frame?.user_id)}
                                                            >
                                                                <SearchIcon sx={{ fontSize: 14 }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                    <Typography variant="caption" color="text.secondary">
                                                        • Mobile: {frame?.mobile_number}
                                                    </Typography>
                                                </Box>
                                                {frame?.bundle_id && (
                                                    <Typography variant="caption" color="text.secondary">
                                                        Bundle ID: {frame?.bundle_id}
                                                    </Typography>
                                                )}
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            {frame?.thumbnail && (
                                                <Box 
                                                    sx={{ 
                                                        position: 'relative',
                                                        width: 150,
                                                        height: 100,
                                                        borderRadius: 2,
                                                        overflow: 'hidden',
                                                        boxShadow: 1,
                                                        '&:hover .zoom-overlay': {
                                                            opacity: 1,
                                                        },
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => handlePreviewOpen(frame.final_url)}
                                                >
                                                    <img 
                                                        src={frame?.thumbnail} 
                                                        alt="frame"
                                                        style={{ 
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover'
                                                        }} 
                                                    />
                                                    <Box
                                                        className="zoom-overlay"
                                                        sx={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            right: 0,
                                                            bottom: 0,
                                                            bgcolor: 'rgba(0, 0, 0, 0.5)',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            opacity: 0,
                                                            transition: 'opacity 0.2s',
                                                        }}
                                                    >
                                                        <ZoomInIcon sx={{ color: 'white', fontSize: 30 }} />
                                                    </Box>
                                                </Box>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Chip 
                                                    label={frame?.status}
                                                    color={frame?.status === 'active' ? 'success' : 'default'}
                                                    size="small"
                                                />
                                                {frame?.is_locked && (
                                                    <Tooltip title="Entry is locked">
                                                        <LockIcon 
                                                            sx={{ 
                                                                fontSize: 18, 
                                                                color: 'warning.main',
                                                                verticalAlign: 'middle'
                                                            }} 
                                                        />
                                                    </Tooltip>
                                                )}
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            {frame?.final_url && (
                                                <Button
                                                    startIcon={<DownloadIcon />}
                                                    onClick={() => handleDownload(frame)}
                                                    size="small"
                                                    sx={{ 
                                                        textTransform: 'none',
                                                        borderRadius: 2
                                                    }}
                                                >
                                                    Download
                                                </Button>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={(event) => handleMenuClick(event, frame.id)}
                                                size="small"
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Enhanced Pagination Section */}
                <Box 
                    sx={{ 
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: 3,
                        py: 2,
                        borderTop: '1px solid',
                        borderColor: 'divider',
                        bgcolor: 'grey.50'
                    }}
                >
                    <Typography 
                        variant="body2" 
                        color="text.secondary"
                    >
                        {`Showing ${frameEntry?.entries?.length || 0} of ${frameEntry?.pagination?.total || 0} entries`}
                    </Typography>
                    
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        size="medium"
                        showFirstButton
                        showLastButton
                        sx={{
                            '& .MuiPaginationItem-root': {
                                borderRadius: 1,
                            },
                            '& .Mui-selected': {
                                fontWeight: 'bold',
                            }
                        }}
                    />
                    
                    <FormControl 
                        variant="outlined" 
                        size="small"
                        sx={{ minWidth: 120 }}
                    >
                        <Select
                            value={limit}
                            onChange={(e) => {
                                setPage(1);
                                setLimit(e.target.value);
                            }}
                            sx={{ 
                                borderRadius: 1,
                                bgcolor: 'white'
                            }}
                        >
                            <MenuItem value={10}>10 per page</MenuItem>
                            <MenuItem value={25}>25 per page</MenuItem>
                            <MenuItem value={50}>50 per page</MenuItem>
                            <MenuItem value={100}>100 per page</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Card>

            {/* Add Menu Items */}
            <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem 
                    onClick={() => handleViewClick(frameEntry?.entries?.find(f => f.id === editEntryId))}
                    sx={{ 
                        '&:hover': { bgcolor: 'primary.lighter' }
                    }}
                >
                    <ListItemIcon>
                        <VisibilityIcon fontSize="small" sx={{ color: 'primary.main' }} />
                    </ListItemIcon>
                    View Details
                </MenuItem>
                <MenuItem 
                    onClick={() => handleDeleteClick(editEntryId)}
                    sx={{ 
                        color: 'error.main',
                        '&:hover': { bgcolor: 'error.lighter' }
                    }}
                >
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" sx={{ color: 'error.main' }} />
                    </ListItemIcon>
                    Delete
                </MenuItem>
            </Menu>

            {/* Add Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle sx={{ pb: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <DeleteIcon sx={{ color: 'error.main' }} />
                        <Typography variant="h6">Confirm Delete</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete this frame entry? This action cannot be undone.
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    <Button
                        onClick={() => setDeleteDialogOpen(false)}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteConfirm}
                        variant="contained"
                        color="error"
                        startIcon={<DeleteIcon />}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Add Image Preview Dialog */}
            <Dialog
                open={previewOpen}
                onClose={handlePreviewClose}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">Image Preview</Typography>
                        <IconButton onClick={handlePreviewClose} size="small">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ p: 0, position: 'relative', minHeight: '70vh' }}>
                    {previewImage && (
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                p: 2
                            }}
                        >
                            <img
                                src={previewImage}
                                alt="Preview"
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '70vh',
                                    objectFit: 'contain'
                                }}
                            />
                        </Box>
                    )}
                </DialogContent>
            </Dialog>

            {/* Add Full Screen Image Dialog */}
            <Dialog
                open={Boolean(fullScreenImage)}
                onClose={() => setFullScreenImage(null)}
                maxWidth="xl"
                fullWidth
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton onClick={() => setFullScreenImage(null)} size="small">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ p: 0, height: '90vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img
                        src={fullScreenImage}
                        alt="Full Screen"
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain'
                        }}
                    />
                </DialogContent>
            </Dialog>

            {/* Modify View Dialog */}
            <Dialog
                open={viewDialogOpen}
                onClose={() => setViewDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">Frame Entry Details</Typography>
                        <IconButton onClick={() => setViewDialogOpen(false)} size="small">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ pt: 2 }}>
                    {selectedFrame && (
                        <Grid container spacing={3}>
                            {/* Action Buttons Section */}
                            <Grid item xs={12}>
                                <Box sx={{ 
                                    display: 'flex', 
                                    gap: 2, 
                                    flexWrap: 'wrap',
                                    mb: 2 
                                }}>
                                    <Button
                                        variant="outlined"
                                        startIcon={<VerifiedIcon />}
                                        disabled
                                        sx={{
                                            borderRadius: 2,
                                            textTransform: 'none',
                                            flex: { xs: '1 1 100%', sm: '1 1 auto' }
                                        }}
                                    >
                                        Mark Verified
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        startIcon={<AutoFixHighIcon />}
                                        disabled
                                        sx={{
                                            borderRadius: 2,
                                            textTransform: 'none',
                                            flex: { xs: '1 1 100%', sm: '1 1 auto' }
                                        }}
                                    >
                                        Generate Image
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        startIcon={<NotificationsIcon />}
                                        disabled
                                        sx={{
                                            borderRadius: 2,
                                            textTransform: 'none',
                                            flex: { xs: '1 1 100%', sm: '1 1 auto' }
                                        }}
                                    >
                                        Send Notification
                                    </Button>
                                </Box>
                            </Grid>

                            {/* Images Section - Update the image containers */}
                            <Grid item xs={12}>
                                <Card elevation={0} sx={{ bgcolor: 'grey.50', p: 2, borderRadius: 2 }}>
                                    <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                                        Images
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Box sx={{ 
                                                p: 2, 
                                                bgcolor: 'white', 
                                                borderRadius: 1, 
                                                height: '100%',
                                                '&:hover': selectedFrame.thumbnail && {
                                                    cursor: 'pointer',
                                                    '& .image-overlay': {
                                                        opacity: 1
                                                    }
                                                }
                                            }}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                                    Thumbnail
                                                </Typography>
                                                {selectedFrame.thumbnail ? (
                                                    <Box 
                                                        sx={{ position: 'relative' }}
                                                        onClick={() => handleImageClick(selectedFrame.thumbnail)}
                                                    >
                                                        <img
                                                            src={selectedFrame.thumbnail}
                                                            alt="Thumbnail"
                                                            style={{
                                                                width: '100%',
                                                                height: 200,
                                                                objectFit: 'cover',
                                                                borderRadius: 8
                                                            }}
                                                        />
                                                        <Box
                                                            className="image-overlay"
                                                            sx={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                right: 0,
                                                                bottom: 0,
                                                                bgcolor: 'rgba(0, 0, 0, 0.5)',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                opacity: 0,
                                                                transition: 'opacity 0.2s',
                                                                borderRadius: 2
                                                            }}
                                                        >
                                                            <ZoomInIcon sx={{ color: 'white', fontSize: 40 }} />
                                                        </Box>
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            height: 200,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            bgcolor: 'grey.100',
                                                            borderRadius: 2,
                                                            flexDirection: 'column',
                                                            gap: 1
                                                        }}
                                                    >
                                                        <ImageNotSupportedIcon sx={{ fontSize: 40, color: 'text.secondary' }} />
                                                        <Typography color="text.secondary">Image not generated</Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box sx={{ 
                                                p: 2, 
                                                bgcolor: 'white', 
                                                borderRadius: 1, 
                                                height: '100%',
                                                '&:hover': selectedFrame.final_url && {
                                                    cursor: 'pointer',
                                                    '& .image-overlay': {
                                                        opacity: 1
                                                    }
                                                }
                                            }}>
                                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                                    Final Image
                                                </Typography>
                                                {selectedFrame.final_url ? (
                                                    <Box 
                                                        sx={{ position: 'relative' }}
                                                        onClick={() => handleImageClick(selectedFrame.final_url)}
                                                    >
                                                        <img
                                                            src={selectedFrame.final_url}
                                                            alt="Final"
                                                            style={{
                                                                width: '100%',
                                                                height: 200,
                                                                objectFit: 'cover',
                                                                borderRadius: 8
                                                            }}
                                                        />
                                                        <Box
                                                            className="image-overlay"
                                                            sx={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                right: 0,
                                                                bottom: 0,
                                                                bgcolor: 'rgba(0, 0, 0, 0.5)',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                opacity: 0,
                                                                transition: 'opacity 0.2s',
                                                                borderRadius: 2
                                                            }}
                                                        >
                                                            <ZoomInIcon sx={{ color: 'white', fontSize: 40 }} />
                                                        </Box>
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            height: 200,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            bgcolor: 'grey.100',
                                                            borderRadius: 2,
                                                            flexDirection: 'column',
                                                            gap: 1
                                                        }}
                                                    >
                                                        <ImageNotSupportedIcon sx={{ fontSize: 40, color: 'text.secondary' }} />
                                                        <Typography color="text.secondary">Image not generated</Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>

                            {/* Details Section */}
                            <Grid item xs={12}>
                                <Card elevation={0} sx={{ bgcolor: 'grey.50', p: 2, borderRadius: 2 }}>
                                    <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                                        Basic Information
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Stack spacing={2}>
                                                <Box>
                                                    <Typography variant="caption" color="text.secondary">
                                                        Entry ID
                                                    </Typography>
                                                    <Typography variant="body2">{selectedFrame.id}</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="caption" color="text.secondary">
                                                        Title
                                                    </Typography>
                                                    <Typography variant="body2">{selectedFrame.title}</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="caption" color="text.secondary">
                                                        Status
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        <Chip 
                                                            label={selectedFrame.status}
                                                            size="small"
                                                            color={selectedFrame.status === 'active' ? 'success' : 'default'}
                                                        />
                                                        {selectedFrame.is_locked ? (
                                                            <Tooltip title="Entry is locked">
                                                                <LockIcon 
                                                                    sx={{ 
                                                                        fontSize: 18, 
                                                                        color: 'warning.main'
                                                                    }} 
                                                                />
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip title="Entry is unlocked">
                                                                <LockOpenIcon 
                                                                    sx={{ 
                                                                        fontSize: 18, 
                                                                        color: 'success.main'
                                                                    }} 
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Stack spacing={2}>
                                                <Box>
                                                    <Typography variant="caption" color="text.secondary">
                                                        User ID
                                                    </Typography>
                                                    <Typography variant="body2">{selectedFrame.user_id}</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="caption" color="text.secondary">
                                                        Mobile Number
                                                    </Typography>
                                                    <Typography variant="body2">{selectedFrame.mobile_number}</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="caption" color="text.secondary">
                                                        Payment Status
                                                    </Typography>
                                                    <Box>
                                                        <Chip 
                                                            label={selectedFrame.subscription_status}
                                                            size="small"
                                                            color={selectedFrame.subscription_status === 'paid' ? 'success' : 'warning'}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>

                            {/* Entry Values Section */}
                            <Grid item xs={12}>
                                <Card elevation={0} sx={{ bgcolor: 'grey.50', p: 2, borderRadius: 2 }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                        <Typography variant="subtitle1" fontWeight={600}>
                                            Entry Values
                                        </Typography>
                                        <Button
                                            startIcon={<AddCircleOutlineIcon />}
                                            onClick={() => handleValueDialogOpen()}
                                            sx={{ borderRadius: 2 }}
                                        >
                                            Add Value
                                        </Button>
                                    </Box>
                                    <TableContainer component={Paper} elevation={0}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Field</TableCell>
                                                    <TableCell>Value</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell align="right">Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {entryValues.map((value) => (
                                                    <TableRow key={value.id}>
                                                        <TableCell>{value.field_name}</TableCell>
                                                        <TableCell>{value.field_value}</TableCell>
                                                        <TableCell>
                                                            {value.is_locked ? (
                                                                <Tooltip title="Field is locked">
                                                                    <LockIcon 
                                                                        sx={{ 
                                                                            fontSize: 18, 
                                                                            color: 'warning.main',
                                                                            verticalAlign: 'middle'
                                                                        }} 
                                                                    />
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip title="Field is unlocked">
                                                                    <LockOpenIcon 
                                                                        sx={{ 
                                                                            fontSize: 18, 
                                                                            color: 'success.main',
                                                                            verticalAlign: 'middle'
                                                                        }} 
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <IconButton
                                                                disabled={value.is_locked}
                                                                size="small"
                                                                onClick={() => handleValueDialogOpen(value)}
                                                                sx={{ color: 'primary.main' }}
                                                            >
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                            <IconButton
                                                                disabled={value.is_locked}
                                                                size="small"
                                                                onClick={() => handleValueDelete(value.id)}
                                                                sx={{ color: 'error.main' }}
                                                            >
                                                                <DeleteIcon fontSize="small" />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Card>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
            </Dialog>

            {/* Add Value Dialog */}
            <Dialog
                open={valueDialogOpen}
                onClose={() => setValueDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {selectedValue ? 'Edit Value' : 'Add Value'}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel>Field</InputLabel>
                            <Select
                                value={valueFormData.field_id}
                                onChange={(e) => setValueFormData({ ...valueFormData, field_id: e.target.value })}
                                label="Field"
                            >
                                {allTemplate.map((template) => (
                                    <MenuItem key={template.id} value={template.id}>
                                        {template.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            label="Value"
                            value={valueFormData.value}
                            onChange={(e) => setValueFormData({ ...valueFormData, value: e.target.value })}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setValueDialogOpen(false)}>Cancel</Button>
                    <Button 
                        variant="contained"
                        onClick={handleValueSubmit}
                    >
                        {selectedValue ? 'Update' : 'Add'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default FrameEntry;
