import React from 'react';
import { Box } from '@mui/material';
import TaskView from './TaskView';

function Dashboard() {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
        <TaskView />
    </Box>
  );
}

export default Dashboard;
