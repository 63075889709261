import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const fetchTemplateFieldRequest = () => ({
    type: actionTypes.GET_TEMPLATE_FIELD_REQUEST,
});
export const fetchTemplateFieldSuccess = (data) => ({
    type: actionTypes.GET_TEMPLATE_FIELD_SUCCESS,
    payload: data,
});
export const fetchTemplateFieldFailure = (error) => ({
    type: actionTypes.GET_TEMPLATE_FIELD_FAILURE,
    payload: error,
});

export const fetchTemplateFieldData = (id) => {
    return async (dispatch) => {
        dispatch(fetchTemplateFieldRequest());
        try{
            const data = await api.getTemplateField(id);
            dispatch(fetchTemplateFieldSuccess(data));
            return data;
        }catch (error){
            dispatch(fetchTemplateFieldFailure(error));
        }
    };
};

export const createTemplateFieldData = async (formData) => {
    const data = await api.createTemplateField(formData.frame_template_id, formData);
    return data;
};

export const updateTemplateFieldData = (formData) => {
    return async (dispatch) => {
        try {
            const data = await api.updateTemplateField(formData.frame_template_id, formData.id, formData);
            dispatch({
                type: 'UPDATE_TEMPLATE_FIELD_SUCCESS',
                payload: data
            });
            return Promise.resolve(data);
        } catch (error) {
            dispatch({
                type: 'UPDATE_TEMPLATE_FIELD_FAILURE',
                payload: error
            });
            return Promise.reject(error);
        }
    };
};

export const deleteTemplateFieldRequest = () => ({
    type: 'DELETE_TEMPLATE_FIELD_REQUEST'
});

export const deleteTemplateFieldSuccess = (data) => ({
    type: 'DELETE_TEMPLATE_FIELD_SUCCESS',
    payload: data
});

export const deleteTemplateFieldFailure = (error) => ({
    type: 'DELETE_TEMPLATE_FIELD_FAILURE',
    payload: error
});

export const deleteTemplateFieldData = (id) => {
    return async (dispatch) => {
        dispatch(deleteTemplateFieldRequest());
        try {
            const response = await fetch(`/api/template-fields/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            dispatch(deleteTemplateFieldSuccess(data));
            return data;
        } catch (error) {
            dispatch(deleteTemplateFieldFailure(error));
            throw error;
        }
    };
};