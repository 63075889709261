import axios from "axios";

const API = axios.create({
    baseURL: `https://account.rajoffset.com/`,
    // baseURL: `http://localhost:3000/`,
});

API.interceptors.request.use((req) => {
    if (localStorage.getItem("access_token")) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("access_token"))}`;
    }
    return req;
});

API.interceptors.response.use((response) => {

    return response;
}, (error) => {
    if (error.response && error.response?.data) {
        return Promise.reject(error.response?.data);
    }
    return Promise.reject(error.message);
})

const EnginePublicAPI = axios.create({
    baseURL: `https://engine.rajoffset.com/`,
    // baseURL: `http://localhost:6000/`,
});


const APIEngine = axios.create({
    baseURL: `https://engine.rajoffset.com/`,
    // baseURL: `http://localhost:6000/`,
});

APIEngine.interceptors.request.use((req) => {
    if (localStorage.getItem("access_token")) {
       // console.log("Access token found", JSON.parse(localStorage.getItem("access_token")));
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("access_token"))}`;
    } else {
        console.log("No access token found")
    }
    return req;
});

APIEngine.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response?.data) {
            return Promise.reject(error.response?.data);
        }
        return Promise.reject(error.message);
    }
);


const VisitingCardLocker = axios.create({
    baseURL: `https://api.visitingcardlocker.com/`,
    // baseURL: `http://localhost:6000/`,
});

VisitingCardLocker.interceptors.request.use((req) => {
    if (localStorage.getItem("access_token")) {
       // console.log("Access token found", JSON.parse(localStorage.getItem("access_token")));
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("access_token"))}`;
    } else {
        console.log("No access token found")
    }
    return req;
});

VisitingCardLocker.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response?.data) {
            return Promise.reject(error.response?.data);
        }
        return Promise.reject(error.message);
    }
);

const TaskManagement = axios.create({
    baseURL: `https://internal-api.rajoffset.com`,
    // baseURL: `http://localhost:6000/`,
});

TaskManagement.interceptors.request.use((req) => {
    if (localStorage.getItem("access_token")) {
       // console.log("Access token found", JSON.parse(localStorage.getItem("access_token")));
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("access_token"))}`;
    } else {
        console.log("No access token found")
    }
    return req;
});

TaskManagement.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response?.data) {
            return Promise.reject(error.response?.data);
        }
        return Promise.reject(error.message);
    }
);



export const login = (form) => API.post(`internal-api-v2/auth/login/`, form);
export const verifyOtp = (form) => API.post(`internal-api-v2/auth/login-verify-otp/`, form);

export const orderCount = () => API.get(`/crm/dashboard/v1/order-count`);

export const cardGroup = (page, limit, search) => APIEngine.get(`/management/invitation/group/v1/?page=${page}&limit=${limit}&search=${search}`);
export const createCardGroup = (form) => APIEngine.post(`/management/invitation/group/v1/`, form);
export const editCardGroup = (id, form) => APIEngine.put(`/management/invitation/group/v1/${id}`, form);
export const deleteCardGroup = (id) => APIEngine.delete(`/management/invitation/group/v1/${id}`);

export const partnerCardGroup = (page, limit, search) => APIEngine.get(`/management/partner-invitation/group/v1/?page=${1}&limit=${50}`);
export const createPartnerCardGroup = (form) => APIEngine.post(`/management/partner-invitation/group/v1/`,form);
export const editPartnerCardGroup = (id, form) => APIEngine.put(`/management/partner-invitation/group/v1/${id}`,form);
export const deletePartnerCardGroup = (id) => APIEngine.delete(`/management/partner-invitation/group/v1/${id}`);

export const cards = (page, limit, search,groupId) => APIEngine.get(`/management/invitation/card/v1/?page=${page}&limit=${limit}&search=${search}&group_id=${groupId}`);
export const allCards = (page, limit, search) => APIEngine.get(`/management/invitation/card/v1/?page=${page}&limit=${limit}&search=${search}`);
export const duplicateCard = (card_id) => APIEngine.get(`/management/invitation/card-duplicate/v1/${card_id}`);
export const mapTemplateInCard = (card_id, template_id) => APIEngine.post(`/management/invitation/card-map/v1/${card_id}`, { template_id: template_id });

export const partnerCards = (page, limit,search,groupId) => APIEngine.get(`/management/partner-invitation/card/v1/?page=${page}&limit=${limit}&search=${search}&group_id=${groupId}`);
export const allPartnerCards = (page, limit, search) => APIEngine.get(`/management/partner-invitation/card/v1/?page=${page}&limit=${limit}`);
export const duplicatePartnerCard = (card_id) => APIEngine.get(`/management/partner-invitation/card-duplicate/v1/${card_id}`);
export const mapTemplateInPartnerCard = (card_id, template_id) => APIEngine.post(`/management/partner-invitation/card-map/v1/${card_id}`, { template_id: template_id });

export const createCards = (form) => APIEngine.post(`/management/invitation/card/v1/`, form);
export const updateCards = (id, form) => APIEngine.put(`/management/invitation/card/v1/${id}`, form);
export const deleteCards = (id) => APIEngine.delete(`/management/invitation/card/v1/${id}`);

export const createPartnerCards = (form) => APIEngine.post(`/management/partner-invitation/card/v1/`, form);
export const updatePartnerCards = (id,form) => APIEngine.put(`/management/partner-invitation/card/v1/${id}`, form);
export const deletePartnerCards = (id) => APIEngine.delete(`/management/partner-invitation/card/v1/${id}`);


export const cardSample = () => APIEngine.get(`/management/invitation/card-sample/v1/`);
export const createCardSample = (form) => APIEngine.post(`/management/invitation/card-sample/v1/`, form);
export const updateCardSample = (id, form) => APIEngine.put(`/management/invitation/card-sample/v1/${id}`, form);
export const deleteCardSample = (id) => APIEngine.delete(`/management/invitation/card-sample/v1/${id}`);
export const getSingleCardSample = (id, form) => APIEngine.get(`/invitation/card/v1/${id}`, form);

export const partnerCardSample = () => APIEngine.get(`/management/partner-invitation/card-sample/v1/`);
export const getSinglePartnerCardSample = (id, form) => APIEngine.get(`/partner-invitation/card/v1/${id}`, form);
export const createPartnerCardSample = (form) => APIEngine.post(`/management/partner-invitation/card-sample/v1/`, form);
export const updatePartnerCardSample = (id, form) => APIEngine.put(`/management/partner-invitation/card-sample/v1/${id}`, form);
export const deletePartnerCardSample = (id) => APIEngine.delete(`/management/partner-invitation/card-sample/v1/${id}`);




export const cardFromField = (card_id) => APIEngine.get(`/management/invitation/card-form_field/v1/?card_id=${card_id}`);
export const createCardFromField = (form) => APIEngine.post(`/management/invitation/card-form_field/v1/`, form);
export const updateCardFromField = (id, form) => APIEngine.put(`/management/invitation/card-form_field/v1/${id}`, form);
export const deleteCardFromField = (id) => APIEngine.delete(`/management/invitation/card-form_field/v1/${id}`);

export const partnerCardFormField = (card_id) => APIEngine.get(`/management/partner-invitation/card-form_field/v1/?card_id=${card_id}`);
export const createPartnerCardFormField = (form) => APIEngine.post(`/management/partner-invitation/card-form_field/v1/`, form);
export const updatePartnerCardFormField = (id, form) => APIEngine.put(`/management/partner-invitation/card-form_field/v1/${id}`, form);
export const deletePartnerCardFromField = (id) => APIEngine.delete(`/management/partner-invitation/card-form_field/v1/${id}`);



export const cardFromFieldOptions = () => APIEngine.get(`/management/invitation/card-form-field-options/v1/`);
export const createCardFromFieldOptions = (form) => APIEngine.post(`/management/invitation/card-form-field-options/v1/`, form);
export const updateCardFromFieldOptions = (id, form) => APIEngine.put(`/management/invitation/card-form-field-options/v1/${id}`, form);
export const deleteCardFromFieldOptions = (id) => APIEngine.delete(`/management/invitation/card-form-field-options/v1/${id}`);

export const partnerCardFormFieldOptions = () => APIEngine.get(`/management/partner-invitation/card-form-field-options/v1/`);
export const createpartnerCardFormFieldOptions = (form) => APIEngine.post(`/management/partner-invitation/card-form-field-options/v1/`, form);
export const updatepartnerCardFormFieldOptions = (id, form) => APIEngine.put(`/management/partner-invitation/card-form-field-options/v1/${id}`, form);
export const deletepartnerCardFormFieldOptions = (id) => APIEngine.delete(`/management/partner-invitation/card-form-field-options/v1/${id}`);


export const cardEntry = (page, limit, search) => APIEngine.get(`/management/invitation/card-entry/v1/?page=${page}&limit=${limit}&search=${search}`);
export const createCardEntry = (form) => APIEngine.post(`/management/invitation/card-entry/v1/`, form);
export const updateCardEntry = (id, form) => APIEngine.put(`/management/invitation/card-entry/v1/${id}`, form);
export const deleteCardEntry = (id) => APIEngine.delete(`/management/invitation/card-entry/v1/${id}`);

export const partnerCardEntry = (page, limit, search) => APIEngine.get(`/management/partner-invitation/card-entry/v1/?page=${page}&limit=${limit}&search=${search}`);
export const createPartnerCardEntry = (form) => APIEngine.post(`/management/partner-invitation/card-entry/v1/`, form);
export const updatePartnerCardEntry = (id, form) => APIEngine.put(`/management/partner-invitation/card-entry/v1/${id}`, form);
export const deletePartnerCardEntry = (id) => APIEngine.delete(`/management/partner-invitation/card-entry/v1/${id}`);



export const cardEntryValues = (form) => APIEngine.get(`/management/invitation/card-entry-field/v1/`, form);
export const createCardEntryValues = (form) => APIEngine.post(`/management/invitation/card-entry-field/v1/`, form);
export const updateCardEntryValues = (id, form) => APIEngine.put(`/management/invitation/card-entry-field/v1/${id}`, form);
export const deleteCardEntryValues = (id) => APIEngine.delete(`/management/invitation/card-entry-field/v1/${id}`);

export const patnerCardEntryValues = (form) => APIEngine.get(`/management/partner-invitation/card-entry-field/v1/`, form);
export const createPartnerCardEntryValues = (form) => APIEngine.post(`/management/partner-invitation/card-entry-field/v1/`, form);
export const updatePartnerCardEntryValues = (id, form) => APIEngine.put(`/management/partner-invitation/card-entry-field/v1/${id}`, form);
export const deletePartnerCardEntryValues = (id) => APIEngine.delete(`/management/partner-invitation/card-entry-field/v1/${id}`);


export const fetchDownloads = (page, limit) => API.get(`/management/my-downloads/v1/?page=${page}&limit=${limit}`);
export const fetchTemplateRecords = () => APIEngine.get(`/management/category/v1/?access=developer`);



export const getAllVideoEngineTemplates = (page, limit) => APIEngine.get(`/management/video-engine/v1/?page=${page}&limit=${limit}`);
export const getSingleVideoEngineTemplates = (templateId) => APIEngine.get(`/management/video-engine/v1/${templateId}`);
export const updateSingleVideoEngineTemplates = (templateId, form) => APIEngine.put(`/management/video-engine/v1/${templateId}`, form);
export const generateSampleOfSingleVideoEngineTemplates = (templateId, form) => APIEngine.get(`/management/invitation/video-card-sample-auto-sample-generate/v1/${templateId}`);


//video-card-sample-auto-sample-generate
{/*
Request URL:       https://engine.rajoffset.com/management/new-engine/v1
Request Method:    POST
*/}

export const getSingleCardDetails = (card_id, group_path, group_type) => APIEngine.get(`/invitation/card/v1/${card_id}/?group_path=${group_path}&group_type=${group_type}`);
export const createCardForCustomer = (id, form) => APIEngine.post(`/invitation/card/v1/${id}`, form);


export const createCardForDiwaliOffer = (form) => APIEngine.post(`/invitation/multiple-card/v1/`, form);

export const getBundles = (page, limit) => APIEngine.get(`/management/invitation/multi-card-entry/v1/?page=${page}&limit=${limit}`);
export const createBundle = (id, form) => APIEngine.post(`/management/invitation/multi-card-entry-payment-status-change/v1/${id}`,form);


export const getAllDigitalCards = () => APIEngine.get(`/invitation/digital-invite-card-attempt/v1/`);
export const getDigitalInvitationCardEntry = () => APIEngine.get(`/management/invitation/digital-invite-entry/v1/`);
// export const createDigitalInviationCardEntry = () => APIEngine.get(`//management/invitation/digital-invite-entry/v1/`);
export const getSingleDigitalInvtCardEntry = (id) => APIEngine.get(`/management/invitation/digital-invite-entry/v1/${id}`);
export const updateDigitalCardEntry = (id,form) => APIEngine.put(`/management/invitation/digital-invite-entry/v1/${id}`,form);
export const deleteDigitalCardEntry = (id) => APIEngine.delete(`/management/invitation/digital-invite-entry/v1/${id}`);

export const updateCardVisibility = (id,form) => APIEngine.put(`/management/invitation/card-visibility/v1/${id}`,form);

export const getAllFrameGroup = () => APIEngine.get(`/political-management/frames/groups`);
export const createFrameGroup = (form) => APIEngine.post(`/political-management/frames/groups`, form);
export const updateFrameGroup = (id, form) => APIEngine.put(`/political-management/frames/groups/${id}`, form);
export const deleteFrameGroup = (id) => APIEngine.delete(`/political-management/frames/groups/${id}`);
export const getSingleFrameGroup = (id) => APIEngine.get(`/political-management/frames/groups/${id}`);

export const getAllTemplate = () => APIEngine.get(`/political-management/frames/templates`);
export const createTemplate = (form) => APIEngine.post(`/political-management/frames/templates`,form);
export const updateTemplate = (id, form) => APIEngine.put(`/political-management/frames/templates/${id}`, form);
export const deleteTemplate = (id) => APIEngine.delete(`/political-management/frames/templates/${id}`);

export const getTemplateField = (id) => APIEngine.get(`/political-management/frames/templates/${id}/fields`);
export const createTemplateField = (id, form) => APIEngine.post(`/political-management/frames/templates/${id}/fields`,form);
export const updateTemplateField = (tempId, id, form) => APIEngine.put(`/political-management/frames/templates/${tempId}/fields/${id}`,form);

export const getFrameEntry = (page, limit, search) => APIEngine.get(`/political-management/frames/entries?page=${page}&limit=${limit}&search=${search}`);
export const createFrameEntry =(form) => APIEngine.post(`/political-management/frames/entries`,form);
export const editFrameEntry = (id, form) => APIEngine.put(`/political-management/frames/entries/${id}`,form);
export const deleteFrameEntry = (id) => APIEngine.delete(`/political-management/frames/entries/${id}`);
export const regenerateFrameEntry = (id, form) => APIEngine.post(`/political-management/frames/entry-action/${id}/regenerate`,form);
export const paymentResyncFrameEntry = (id, form) => APIEngine.post(`/political-management/frames/entry-action/${id}/payment-resync`,form);
export const downloadFrameEntry = (id, form) => APIEngine.post(`/political-management/frames/entry-action/${id}/download`,form);
export const suspendFrameEntry = (id, form) => APIEngine.post(`political-management/frames/entry-action/${id}/suspend`,form);

export const getCustomGroup = () => APIEngine.get(`/political-management/custom/groups`);
export const createCustomGroup = (form) => APIEngine.post(`/political-management/custom/groups`,form);
export const updateCustomGroup = (id, form) => APIEngine.put(`/political-management/custom/groups/${id}`,form);
export const deleteCustomGroup = (id) => APIEngine.delete(`/political-management/custom/groups/${id}`);

export const getCustomTemplate = () => APIEngine.get(`/political-management/custom/templates`);
export const createCustomTemplate = (form) => APIEngine.post(`/political-management/custom/templates`,form);

export const cloneFrameTemplate = (id) => APIEngine.post(`/political-management/frames/templates-clone/${id}`);

export const allBusinesses = () => VisitingCardLocker.get(`/general/business/v1/`);

export const allUsers = () => TaskManagement.get(`/general/user/v1/`);
export const addUser = (form) => TaskManagement.post(`/general/user/v1/create`,form);

export const allTasks = () => TaskManagement.get(`/general/task/v1/`);
export const addTasks = (userId, form) => TaskManagement.post(`/general/task/v1/add/${userId}`, form);
export const allTasksByStatus = (status) => TaskManagement.get(`/general/task/v1/filter/?status=${status}`);
export const updateStatus = (id, form) => TaskManagement.put(`/general/task/v1/update/${id}`, form);
export const deleteTasks = (id) => TaskManagement.delete(`/general/task/v1/delete/${id}`);
export const addComments = (id, form) => TaskManagement.post(`/general/comment/v1/add/${id}`, form);
export const imageUpload = (id, form) => TaskManagement.post(`/general/attachment/v1/add/${id}`, form);

// Project APIs
export const getAllProjects = () => TaskManagement.get('/general/project/v1/');
export const createProject = (formData) => TaskManagement.post('/general/project/v1/create/', formData);
export const updateProject = (id, formData) => TaskManagement.put(`/general/project/v1/update/${id}`, formData);
export const deleteProject = (id) => TaskManagement.delete(`/general/project/v1/delete/${id}`);

// Frame Entry Value APIs
export const getFrameEntryValue = (id) => APIEngine.get(`/political-management/frames/entries/${id}/fields`);
export const createFrameEntryValue = (id, form) => APIEngine.post(`/political-management/frames/entries/${id}/fields`, form);
export const updateFrameEntryValue = (entryId, id, form) => APIEngine.put(`/political-management/frames/entries/${entryId}/fields/${id}`, form);
export const deleteFrameEntryValue = (entryId, id) => APIEngine.delete(`/political-management/frames/entries/${entryId}/fields/${id}`);




