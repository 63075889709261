import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Menu, MenuItem, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { isAllowedToAccess } from '../../components/PermissionManager';
import { createCustomGroupData, deleteCustomGroupData, getCustomGroupData, updateCustomGroupData } from '../../redux/actions/customGroupActions';



function CustomGroup() {
    const dispatch = useDispatch();

    // Destructure with default values to avoid undefined errors
    const customGroup = useSelector((state) => state?.customGroup?.customGroupData?.data?.data?.groups || []);
    console.log("custom group", customGroup);

    // const userData = JSON.parse(localStorage.getItem("user"));
    // const accessRole = userData?.accessRole;
    // console.log(accessRole);



    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenuClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setEditGroupId(id);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setEditGroupId(null);
    };

    // pagination 

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState('');
    const limit = 50;

    useEffect(() => {
        getCustomGroupData(page, limit);
    }, [page]);

    const handlePageChange = async (event, value) => {
        console.log("value", value);
        await setPage(value);
        await dispatch(getCustomGroupData(value, limit));
    };

    const handleSearch = async () => {
        setPage(1);
        setLoading(true);
        await dispatch(getCustomGroupData(1, limit, search));
        setLoading(false);
    };

    // Fetch card group data on component mount
    useEffect(() => {
        const fetchCardsGroupData = async () => {
            setLoading(true);
            let rsp = await dispatch(getCustomGroupData(page, limit, search));
            let total = rsp?.data?.total;
            let pages = Math.ceil(total / Number(limit));
            const pageValue = isNaN(pages) ? Number(pages) : parseFloat(pages);
            if (!isNaN(pageValue)) {
                setTotalPages(pages);
            }

            setLoading(false);
        };

        fetchCardsGroupData();
    }, [dispatch, page, limit, search]);

    const columns = [
        { id: 'group-id', name: 'Group Id' },
        { id: 'name', name: 'Group Name' },
        { id: 'description', name: 'Group Description' },
        { id: 'order', name: 'Group Order' },
        { id: 'type', name: 'Group Type' },
        { id: 'status', name: 'Group Status' },
        { id: 'actions', name: 'Actions' }
    ];

    // Add functionality
    const [open, setOpen] = useState(false);
    const [groupName, setGroupName] = useState('');
    const [groupDescription, setGroupDescription] = useState('');
    const [groupOrder, setGroupOrder] = useState('');
    const [groupType, setGroupType] = useState('');


    const functionAdd = () => {
        openPopup();
    };

    const closePopup = () => {
        setOpen(false);
    };

    const openPopup = () => {
        setOpen(true);
    };

    const handleCustomGroupSubmit = async (e) => {
        e.preventDefault();
        const newCustomGroupData = {
            name: groupName,
            description: groupDescription,
            order: groupOrder,
            type: groupType,
        };
        const response = await createCustomGroupData(newCustomGroupData);
        if (response?.status === 'success') {
            toast.success('Card Group submitted successfully!', {
                autoClose: 3000,
            });
        } else {
            toast.error('Failed to submitted card Group. Please try again.', {
                autoClose: 3000,
            });
        }
        await dispatch(getCustomGroupData(page, limit, search));
        closePopup();
    };

    // Edit functionality 

    const [editOpen, setEditOpen] = useState(false);
    const [editGroupId, setEditGroupId] = useState('');
    const [editGroupName, setEditGroupName] = useState('');
    const [editGroupDesc, setEditGroupDesc] = useState('');
    const [editGroupOrder, setEditGroupOrder] = useState('');
    const [editGroupType, setEditGroupType] = useState('');

    const functionEdit = useCallback((card) => {
        setEditGroupId(card.id);
        setEditGroupName(card.name);
        setEditGroupDesc(card.description);
        setEditGroupOrder(card.order);
        setEditGroupType(card.type);
        setEditOpen(true);
    }, []);

    const closeEditPopup = () => {
        setEditOpen(false);
    };

    const handleCustomGroupEditSubmit = async (e) => {
        e.preventDefault();
        const updateCardGroup = {
            id: editGroupId, name: editGroupName, order: editGroupOrder,
            type: editGroupType, description: editGroupDesc
        }

        const response = await updateCustomGroupData(updateCardGroup);
        // if (response?.payload?.status === 'success') {


        //     toast.success('Card updated successfully!', {
        //         autoClose: 3000, // Auto-close after 3 seconds
        //     });
        // } else {
        //     toast.error('Failed to update card. Please try again.', {
        //         autoClose: 3000,
        //     });
        // }
        await dispatch(getCustomGroupData(page, limit, search));
        closeEditPopup();
        handleMenuClose();
    };


    // validation 
    const validateCreateFields = () => {
        return groupOrder !== '' && !isNaN(groupOrder);
    };

    //delete frame group 
    const [deleteCustomId, setDeleteCustomId] = useState();
    const [deleteOpen, setDeleteOpen] = useState(false);

    const functionCustomGroupDelete = useCallback((id) => {
        setDeleteCustomId(id);
        setDeleteOpen(true);
    }, []);

    const closeDeleteCustomPopup = () => {
        setDeleteOpen(false);
    };

    const handleCustomDelete = async () => {
        if (deleteCustomId) {

            const response = await deleteCustomGroupData(deleteCustomId);
            await dispatch(getCustomGroupData(page, limit, search));
            closeDeleteCustomPopup();
            handleMenuClose();
        }

    };

    //navigation 
    const handleNavigation = (id) => {
        const queryParams = new URLSearchParams({
            group_id: id,
        }).toString();
        navigate(`/cards/card-group/card?${queryParams}`);
    };



    const accessRole = useSelector((state) => state?.auth?.user?.user_info?.meta_data);

    return (
        <>
            {isAllowedToAccess(accessRole, 'mainwebsite_role', 'designer') ? (
                <Box>
                    <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: { xs: 'flex-start', md: 'space-between' }
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                            }}>
                                <Typography sx={{
                                    fontSize: { xs: '20px', sm: '24px', md: '28px' },
                                    mb: { xs: 2, md: 0 }
                                }}>
                                    Custom Group
                                </Typography>
                                <TextField
                                    size="small"
                                    placeholder="Search"
                                    sx={{
                                        width: { xs: '100%', sm: '200px' },
                                        borderRadius: '20px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '20px'
                                        }
                                    }}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={handleSearch}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                gap: { xs: 1, md: 0.5 },
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: { xs: 'center', md: 'flex-end' },
                                width: { xs: '100%', md: 'auto' },
                                alignItems: 'center'
                            }}>
                                {isAllowedToAccess(accessRole, 'mainwebsite_role', 'manager') &&
                                    <Button
                                        variant='contained'
                                        sx={{
                                            backgroundColor: 'midnightblue',
                                            textTransform: 'none',
                                            fontSize: { xs: '12px', sm: '14px', md: '16px' },
                                            width: { xs: '100%', sm: 'auto' }
                                        }}
                                        onClick={functionAdd}
                                    >
                                        Add Custom Group
                                    </Button>
                                }

                            </Box>
                        </Box>
                    </Toolbar>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell key={column.id}>{column.name}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            <Loading />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <>
                                        {Array.isArray(customGroup) && customGroup.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell>{row.id}</TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.description}</TableCell>
                                                <TableCell>{row.order}</TableCell>
                                                <TableCell>{row.type}</TableCell>

                                                <TableCell>{row.group_status}</TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        aria-controls="simple-menu"
                                                        aria-haspopup="true"
                                                        onClick={(event) => handleMenuClick(event, row.id)}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id="simple-menu"
                                                        anchorEl={anchorEl}
                                                        keepMounted
                                                        open={menuOpen && editGroupId === row.id}
                                                        onClose={handleMenuClose}
                                                    >
                                                        {isAllowedToAccess(accessRole, 'mainwebsite_role', 'manager') &&
                                                            <>
                                                                <MenuItem onClick={() => functionEdit(row)}>Edit</MenuItem>
                                                                <MenuItem onClick={() => handleNavigation(row?.id)}>Cards</MenuItem>
                                                            </>
                                                        }

                                                        <br />

                                                        {isAllowedToAccess(accessRole, 'mainwebsite_role', 'admin') &&
                                                            <MenuItem onClick={() => functionCustomGroupDelete(row?.id)}>Delete</MenuItem>
                                                        }

                                                    </Menu>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                )}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1%' }}>
                        <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
                    </Box>

                </Box>
            ) : (
                <Typography>You don't have any permission</Typography>
            )}
            <Dialog open={open} onClose={closePopup}>
                <DialogTitle>
                    Add Card Group
                    <IconButton onClick={closePopup}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleCustomGroupSubmit}>
                        <TextField
                            label="Group Name"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Group Description"
                            value={groupDescription}
                            onChange={(e) => setGroupDescription(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            type='number'
                            required
                            label="Group Order"
                            value={groupOrder}
                            error={isNaN(groupOrder)}
                            onChange={(e) => setGroupOrder(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Group Type"
                            value={groupType}
                            onChange={(e) => setGroupType(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <DialogActions>
                            <Button onClick={closePopup} color="primary">Cancel</Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={!validateCreateFields()}
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog open={editOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Edit Card Group</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeEditPopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleCustomGroupEditSubmit}>
                        <Stack spacing={2} margin={2}>

                            <TextField required variant='outlined' value={editGroupName} onChange={e => setEditGroupName(e.target.value)} label="Group Name" />
                            <TextField required variant='outlined' value={editGroupDesc} onChange={e => setEditGroupDesc(e.target.value)} label="Group Description" />
                            <TextField required variant='outlined' value={editGroupOrder} onChange={e => setEditGroupOrder(e.target.value)} label="Group Order" />
                            <TextField required variant='outlined' value={editGroupType} onChange={e => setEditGroupType(e.target.value)} label="Group Type" />

                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeEditPopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Update</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>


            {/*delete dialog*/}
            <Dialog
                open={deleteOpen}
                onClose={closeDeleteCustomPopup}
                fullWidth
                maxWidth="sm"
                PaperProps={{
                    sx: {
                        borderRadius: 3,
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                        padding: 3,
                        backgroundColor: '#f9f9f9',
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #ddd',
                        paddingBottom: 1,
                    }}
                >
                    <DialogTitle
                        sx={{
                            flexGrow: 1,
                            textAlign: 'center',
                            marginTop: 2,
                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                            color: '#333',
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
                        >
                            Delete Frame
                        </Typography>
                    </DialogTitle>
                    <IconButton onClick={closeDeleteCustomPopup} sx={{ color: '#555' }}>

                    </IconButton>
                </Box>
                <DialogContent sx={{ marginTop: 3, textAlign: 'center' }}>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            fontSize: '1.1rem',
                            color: '#666',
                        }}
                    >
                        Are you sure you want to delete this Frame?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', gap: 2, marginTop: 2 }}>
                    <Button
                        variant="outlined"
                        sx={{
                            borderColor: '#ddd',
                            color: '#333',
                            '&:hover': {
                                backgroundColor: '#f0f0f0',
                                borderColor: '#ccc',
                            },
                        }}
                        onClick={closeDeleteCustomPopup}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{
                            color: '#fff',
                            boxShadow: '0px 4px 10px rgba(255, 0, 0, 0.3)',
                            '&:hover': {
                                backgroundColor: '#d32f2f',
                            },
                        }}
                        onClick={handleCustomDelete}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
}

export default CustomGroup;