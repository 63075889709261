import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const getFrameEntryValueRequest = () => ({
    type: actionTypes.GET_FRAME_ENTRY_VALUE_REQUEST,
});

export const getFrameEntryValueSuccess = (data) => ({
    type: actionTypes.GET_FRAME_ENTRY_VALUE_SUCCESS,
    payload: data,
});
export const getFrameEntryValueFailure = (error) => ({
    type: actionTypes.GET_FRAME_ENTRY_VALUE_FAILURE,
    payload: error,
});

export const getFrameEntryValueData = (id) => {
    return async(dispatch) => {
        dispatch(getFrameEntryValueRequest());
        try{
            const data = await api.getFrameEntryValue(id);
            
            dispatch(getFrameEntryValueSuccess(data));
            return data;
        }catch(error){
            dispatch(getFrameEntryValueFailure());
        }
    }
};

export const createFrameEntryValueData = async(formData) => {
    const data = await api.createFrameEntryValue(formData.entry_id, formData);
    return data;
};

export const updateFrameEntryValueData = async (formData) => {
    const data = await api.updateFrameEntryValue(formData.entry_id, formData.id, formData);
    return data;
};
export const deleteFrameEntryValueData = async (formData) => {
    const data = await api.deleteFrameEntryValue(formData.entry_id, formData.id);
    return data;
}
