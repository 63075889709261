import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const fetchAllUsersRequest = () => ({
    type: actionTypes.GET_ALL_USERS_REQUEST,
});
export const fetchAllUsersSuccess = (data) => ({
    type: actionTypes.GET_ALL_USERS_SUCCESS,
    payload: data,
});
export const fetchAllUsersFailure = (error) => ({
    type: actionTypes.GET_ALL_USERS_FAILURE,
    payload: error,
});

export const fetchAllUsersData = () => {

    return async (dispatch) => {
        dispatch(fetchAllUsersRequest());
        try {
            const {data} = await api.allUsers();
            console.log("users data", data);
            dispatch(fetchAllUsersSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchAllUsersFailure(error));
        }
    };
};

export const createUser = async (formData) => {
    console.log("formData in Action",formData);
    const {data} = await api.addUser(formData);
    return data;
}