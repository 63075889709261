import * as api from "../../api/index";

export const fetchAllProjectsRequest = () => ({
    type: 'GET_ALL_PROJECTS_REQUEST',
});

export const fetchAllProjectsSuccess = (data) => ({
    type: 'GET_ALL_PROJECTS_SUCCESS',
    payload: data,
});

export const fetchAllProjectsFailure = (error) => ({
    type: 'GET_ALL_PROJECTS_FAILURE',
    payload: error,
});

export const fetchAllProjectsData = () => {
    return async (dispatch) => {
        dispatch(fetchAllProjectsRequest());
        try {
            const { data } = await api.getAllProjects();
            dispatch(fetchAllProjectsSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchAllProjectsFailure(error));
        }
    };
};

export const createProjectData = async (formData) => {
    const data = await api.createProject(formData);
    return data;
};

export const updateProjectData = async (formData) => {
    const data = await api.updateProject(formData.project_id, formData);
    return data;
};

export const deleteProjectData = async (id) => {
    const data = await api.deleteProject(id);
    return data;
}; 