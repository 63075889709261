import * as actionTypes from '../actionTypes.js';

const initialState = {
    frameEntryData: null,
    loading: false,
    error: null,
};

const frameEntryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_FRAME_ENTRY_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case actionTypes.GET_ALL_FRAME_ENTRY_SUCCESS:
            return {
                ...state,
                loading: false,
                frameEntryData: action.payload,
            };
        case actionTypes.GET_ALL_FRAME_ENTRY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}
export default frameEntryReducer